import { GATEWAY_BASE_URL } from '../../../../constants';
import request from '../../../../util/APIUtils';

/**
 * Constants for Manage Ads
 */
export const MANAGE_ADS_CONSTANTS = {
  FEATURE_NAME: 'Enable_Ads',
  ALLOWED_VIDEO_FORMATS: ['video/mp4'],
  MAX_VIDEO_SIZE: 10000000, // 10MB
};

/**
 * Function to get the size of a file in MB
 *
 * @param {number} size - The size of the file in bytes
 * @returns {string} - The size of the file in MB upto 1 decimal place
 */
export const getSizeInMB = (size) => {
  return (size / 1000000).toFixed(1);
};

/**
 * Function to transform ad object
 *
 * @param {object} ad - Ad object
 * @returns {object} - Transformed ad object
 */
const transformAd = (ad) => ({
  id: ad.id,
  name: ad.name,
  uploadedDate: new Date(ad.uploadedAt).toLocaleDateString(),
  size: ad.size,
  url: ad.url,
  status: ad.status,
  ctaData:{
    enabled: ad.ctaData?.enabled,
    actionButtonText: ad.ctaData?.actionButtonText,
    link: ad.ctaData?.link,
  }
});

/**
 * Function to transform ads array
 *
 * @param {array} ads - Ads array
 * @returns {array} - Transformed ads array
 */
const transformAds = (ads) => {
  return ads?.map(transformAd) || [];
};

/**
 * Function to fetch active ads
 *
 * @returns {Promise} - Promise object
 */
export const fetchActiveAds = async () => {
  return new Promise((resolve, reject) => {
    request({
      url: `${GATEWAY_BASE_URL}/api/admin/advertisements`,
      method: 'GET',
      throw400Error: true,
    })
      .then((response) => {
        if (response) {
          const { advertisements, featureStatus } = response;
          resolve({
            featureStatus,
            advertisements: transformAds(advertisements),
          });
        }
        resolve({
          featureStatus: true,
          advertisements: [],
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to toggle ads visibility
 *
 * @param {boolean} isEnabled - Whether the ads are enabled
 * @returns {Promise} - Promise object
 */
export const toggleAdsVisibility = async (isEnabled) => {
  const payload = { featureName: MANAGE_ADS_CONSTANTS.FEATURE_NAME, enabled: isEnabled };
  return new Promise((resolve, reject) => {
    request({
      url: `${GATEWAY_BASE_URL}/root-location/feature/update`,
      method: 'PUT',
      body: JSON.stringify(payload),
      throw400Error: true,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to delete an ad
 *
 * @param {number} id - Ad id
 * @returns {Promise} - Promise object
 */
export const deleteAd = async (id, buttonLabel, buttonUrl) => {
  const payload = { 
    ctaData: {
      actionButtonText: buttonLabel,
      link: buttonUrl,
      enabled: false,
    },
    enabled: false,
  };
  return new Promise((resolve, reject) => {
    request({
      url: `${GATEWAY_BASE_URL}/api/admin/advertisement/${id}`,
      method: 'PUT',
      body: JSON.stringify(payload),
      throw400Error: true,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to upload an ad
 *
 * @param {object} file - File object
 * @returns {Promise} - Promise object
 */
export const uploadAd = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return new Promise((resolve, reject) => {
    request({
      url: `${GATEWAY_BASE_URL}/api/admin/advertisement/upload`,
      method: 'POST',
      body: formData,
      throw400Error: true,
    })
      .then((response) => {
        resolve(transformAd(response));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/**
 * Function to upload CTA data
 *
 * @param {string} buttonLabel - buttonLabel string
 * @param {string} buttonUrl - buttonUrl string
 * @param {boolean} isEnabled - buttonUrl string
 * @param {number} id - id of the ad
 * @param {boolean} isAdEnabled - isAdEnabled boolean
 * @returns {Promise} - Promise object
 */

export const uploadCTAData = async (id, isEnabled, buttonLabel, buttonUrl, isAdEnabled) => {
  const payload = { 
    ctaData: {
      actionButtonText: buttonLabel,
      link: buttonUrl,
      enabled: isEnabled,
    },
    enabled: isAdEnabled,
  };

  return new Promise((resolve, reject) => {
    request({
      url: `${GATEWAY_BASE_URL}/api/admin/advertisement/${id}`,
      method: 'PUT',
      body: JSON.stringify(payload),
      throw400Error: true,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};