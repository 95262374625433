import React from 'react';
import { Chip } from '@orijinworks/frontend-commons';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-insight-chip.style';

const VoiceInsightChip = ({ label, classes }) => {
  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      classes={{
        root: classes.rootChip,
        outlined: classes.chipOutlined,
      }}
    />
  );
};

VoiceInsightChip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(VoiceInsightChip);
