import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POLL_CONFIG } from '../../../constants';
import {
  rsSetDirectMessages,
} from '../../core/store/reducers/messageModuleReducer';
import { registerAction } from '../../socket';

/**
 * A method responsible to check the personID and change the state of the relationship
 * in redux store
 * @param {*} data
 */
const changeRelationShipStatus = (data, rsMessageModuleStore, dispatch) => {
    const {
        rsMessageThread = [],
    } = rsMessageModuleStore;
    rsMessageThread.map((item) => {
        if (item.contactId === data.firstPersonKey || item.contactId === data.secondPersonKey) {
            item.messageRelationshipStatus = data.relationshipStatus;
        }
    });

    dispatch(rsSetDirectMessages({ rsMessageThread }));
};

const useStaffRelationStatusChange = () => {
  const dispatch = useDispatch();
  const rsMessageModuleStore = useSelector((state) => {
    return state.rsMessageModuleStore;
  });
  React.useEffect(() => {
    if (!POLL_CONFIG.USE_POLLING) {
      registerAction({
        event: 'general_message_event: relationship',
        action: (data) => changeRelationShipStatus(data, rsMessageModuleStore, dispatch),
      });
    }
  });
};

export default useStaffRelationStatusChange;
