import React, { Suspense } from 'reactn';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withKeycloak } from '@react-keycloak/web';
import { Box, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Logging } from '../util/logging/Logging';
import { SuperAdminRoutes, AdminRoutes, AttorneyRoutes, TeacherRoutes, PermittedRoutes } from '../routes';
import {
  JURISDICTION_WITH_CHILDREN,
  LOCATIONS,
  POLL_CONFIG,
  SELECTED_LOCATION,
  setJurisdictionWithChildren,
  setHousingUnit as setDefaultHousingUnit,
  setLocation as setDefaultSelectedLocation,
  setJanusToken,
} from '../constants';
import { listLocations, fetchJanusToken, getUnreadMessagesByLocationIds } from '../util/APIUtils';
import { USER_CONTEXT, setUserContext, setLocations, setHousingUnits } from '../constants';
import { isPermitted } from './../components/permission-control/permission-control';
import CbacContainer from './../components/cbac-container';
import { Loader, Typography } from '@orijinworks/frontend-commons';
import StaffProfile from './management/staff/staff-profile/staff-profile';
import SessionOut from './session-out/session-out';
import {
  checkMessagingFeatureFlag,
  handleLocationSettingsPermission,
  handleMessageFacilityPermission,
} from './users/learner/transcript/utils';
import IdleTimeout from './idle-timeout/idle-timeout';
import { joinRoom } from '../v2/socket';
import { UserStorage } from '../util/UserStorage';
import { setLocation as selectLocation, hideToast, setHousingUnit, currentUser, changeToastType } from '../v2/core/store/.';
import { RELATIVE_POSITIONS } from '../theme';
import { usePendoInitialize } from '../v2/shared/hooks/.';
import { EVENTS, LOCATION_CACHE_KEY, SNACK_BAR_SUCCESS, SNACK_BAR_TIMEOUT } from '../v2/services/constants';
import { checkVoiceCallFeatureFlag } from './voice-call/utils/utils';
import { MessagingRedirect } from '../v2/shared/components/messaging-redirect';
import UnleashProvider from '../v2/core/unleash/unleashProvider';
import { UnleashService } from '../v2/services';
import useStaffMessageSocket from '../v2/shared/hooks/useStaffMessageSocket';
import useStaffRelationStatusChange from '../v2/shared/hooks/useStaffRelationStatusChange';
import { SessionExpire } from '@orijinworks/frontend-commons';
import useUnleashHook from '../v2/core/unleash/useUnleashHook';
import { withAuth0 } from '@auth0/auth0-react';
import { getTokenClaims, handleAuthentication, handleLogout, isAuthenticated } from '../v2/core/auth/utils';
import { setUnreadCountByLocation } from '../v2/core/store/reducers/messageModuleReducer';
import { MessageService } from '../v2/services/message.service';
import { eventEmitter } from '../v2/services/event-emitter-service';

const MainNav = React.lazy(() => import('../v2/core/containers/mainnav/index'));
const SideMenu = React.lazy(() => import('../v2/core/containers/side-menu/index'));
/*
Higher level styles for material components
You'll notice that certain inline styles won't apply because componens in react are rendered in a virtual dom.
These styles are injected to the component at the construction level.
*/

const styles = (theme) => ({
  root: {
    display: 'relative',
    height: '100vh',
    width: '100vw',
    background: theme.palette.primary.inverseGradient,
  },
  margin: {
    margin: theme.spacing(1),
  },
  mainApplication: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    flexDirection: 'row',
  },
  mainSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: `calc(100vh - 136px)`,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.white, //'#F7F8FA'
    padding: RELATIVE_POSITIONS.MESSAGE_MODULE_PADDING + 'px',
    width: '100%',
    boxSizing: 'border-box',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: '100%',
  },
  paper: {
    height: 76,
    width: 480,
    margin: 'auto',
    marginBottom: '.5rem',
  },
  studentLoginBtn: {
    position: 'absolute',
    color: 'white',
    right: 0,
    top: 0,
  },
  inputs: {
    width: 320,
    height: 48,
    fontSize: 24,
  },
  loginBox: {
    margin: 'auto',
    maxWidth: 520,
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  confirmBtn: {
    margin: '2rem',
    background: theme.palette.primary.main,
    color: 'white',
    width: 230,
    height: 42,
  },
  footer: {
    padding: '0px 15px',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderTop: '1px solid var(--grey-60)',
    color: theme.palette.colors.aquaLight,
    '& ul': {
      margin: 0,
      padding: 0,
    },
    '& a': {
      color: theme.palette.colors.aquaLight,
    },
    '& li': {
      listStyle: 'none',
      display: 'inline-block',
      padding: '0px 10px',
      borderRight: '1px solid ' + 'var(--grey-60)',
      margin: '10px 0px',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
});
class AuthContainer extends React.Component {
  loggingService = new Logging();
  userStorageService = null;
  snackBarTimeout = null;
  isInitialSetupDone = false;
  BASE_URL = window.location.origin;
  constructor(props) {
    super(props);
    this.state = {
      authorize: false,
      isLoggedin: false,
      context: 'admin',
      clientIP: '',
      internalIp: '',
      currentUser: null,
      isAuthenticated: false,
      isLoading: false,
      userContext: null,
      locationLoading: false,
      huLoading: false,
      locations: null,
      housingUnits: {},
      error: false,
      receivedLocations: false,
      errorMessage: '',
      permissionsLoaded: false,
    };
  }

  jurisdictionsWithChildren = (facilities) => {
    let jurisdictions = facilities.filter(
      (item) => item.locationTypeId === parseInt(process.env.REACT_APP_APDS_JURISDICTION_ID)
    );

    for (let i = 0; i < jurisdictions.length; i++) {
      let locationId = jurisdictions[i].locationId;
      let childLocations = facilities.filter((item) => item.parentLocationId === locationId && item.locationTypeId === 3);
      if (childLocations && childLocations.length > 0) {
        jurisdictions[i].children = this.getSortedLocations(childLocations);
      } else {
        delete jurisdictions[i].children;
      }
    }

    jurisdictions = jurisdictions
      .filter((item) => !!item.children)
      .sort((a, b) => {
        if (a.locationName < b.locationName) return -1;
        if (a.locationName > b.locationName) return 1;
        return 0;
      });

    return jurisdictions;
  };

  getUnreadCountInBatches = (locationIds, startIndex, unreadCountCollection) => {
    const BATCH_SIZE = 500;
    let locationIdsBatch = locationIds.splice(startIndex, BATCH_SIZE).join(',');
    getUnreadMessagesByLocationIds(locationIdsBatch).then((data) => {
      unreadCountCollection.push(unreadCountCollection);
      if (data.length >= BATCH_SIZE) {
        this.getUnreadCountInBatches(locationIds, startIndex + BATCH_SIZE);
      }
    });
  };

  getUnreadCountByLocations = async (locations) => {
    let unreadCountCollection = [];
    let locationIds = locations.map((location) => location.locationId);
    let unreadCountResponse = await Promise.all([
      getUnreadMessagesByLocationIds(locationIds.slice(0, 500).join(',')),
      getUnreadMessagesByLocationIds(locationIds.slice(500, 1000).join(',')),
      getUnreadMessagesByLocationIds(locationIds.slice(1000, 1500).join(',')),
    ]);
    unreadCountResponse.forEach((response) =>
      response.forEach((unreadData) => {
        unreadCountCollection.push(unreadData);
      })
    );
    this.props.setUnreadCountByLocation(unreadCountCollection);
  };

  getSortedLocations = (locations) => {
    const messaging = this.props.messaging || false;

    const sortedLocations = locations.sort(function (a, b) {
      var keyA = a.locationName,
        keyB = b.locationName;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    if (messaging) {
      sortedLocations.forEach((location) => {
        location.totalCount = this.state.unreadLocationsMessages[location.locationId] || 0;
      });
    }

    return sortedLocations;
  };

  fetchLocations = () => {
    if (this.state.locations || this.state.locationLoading) {
      return;
    }
    this.setState({ locationLoading: true });

    let pBody = '';
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      filterLocationTypeIds: [],
      filterLocationIds: [],
    };

    pBody = JSON.stringify(postBody);
    this.setGlobal({
      permissionLoadingStack: this.global.permissionLoadingStack + 1,
    });
    listLocations(pBody).then((data) => {
      const { cbacConfigFlag } = this.global;
      const locations = data.locations
        .filter((l) => {
          return l.active === true;
        })
        .sort();
      setLocations(locations);
      if (!POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
        this.getUnreadCountByLocations(locations);
      }

      if (SELECTED_LOCATION) {
        const housingUnits = data.locations
          .filter((l) => l.active && l.locationTypeId === 15 && l.parentLocationId == SELECTED_LOCATION.locationId)
          .sort();
        setHousingUnits(housingUnits);
      }

      checkVoiceCallFeatureFlag(this.setGlobal, locations);
      if (cbacConfigFlag) {
        handleMessageFacilityPermission(locations, this.setGlobal, this.global);
        handleLocationSettingsPermission(locations, this.setGlobal, this.global, this.global.isSuperAdmin);
      } else {
        checkMessagingFeatureFlag(this.setGlobal, locations);
      }
      this.setState({
        locationLoading: false,
        locations: data.locations,
      });
      setJurisdictionWithChildren(this.jurisdictionsWithChildren(locations));
      this.selectLocationIfNotCached();
      this.setGlobal({
        permissionLoadingStack: this.global.permissionLoadingStack - 1,
        jurisdictionWithChildren: this.jurisdictionsWithChildren(locations),
      });
    });
  };

  selectLocationIfNotCached = () => {
    // Initialize UserStorageService
    this.userStorageService = new UserStorage(USER_CONTEXT.personId);

    // Retrieve cached location
    let cachedLocation = this.userStorageService.getItem(LOCATION_CACHE_KEY);

    // Check if cached location is not available
    if (!cachedLocation) {
      // Check if there are multiple jurisdictions and no selected location
      if (JURISDICTION_WITH_CHILDREN && JURISDICTION_WITH_CHILDREN.length > 0 && !SELECTED_LOCATION.locationId) {
        // Set the first location as selected
        eventEmitter.emit( EVENTS.LOCATION_CHANGE, {facility: JURISDICTION_WITH_CHILDREN[0].children[0], housingUnit: {}});
        this.setSelectedLocation(JURISDICTION_WITH_CHILDREN[0].children[0]);
      }
    }
  };

  setSelectedLocation = async (location, type) => {
    if (location) {
      this.props.selectLocation(location);
      setDefaultSelectedLocation(location);
      setDefaultHousingUnit(null);
      setHousingUnit(null);
      const janusToken = await fetchJanusToken(location.locationId);
      setJanusToken(janusToken.token || '');
    }
  };

  fetchHousingUnits = (parentLocation) => {
    if (!parentLocation || this.state.huLoading || parentLocation === this.state.housingUnits.parentLocation) {
      return;
    }
    this.setState({ huLoading: true });

    let pBody = '';
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      filterLocationTypeIds: [15],
      filterLocationIds: [parentLocation.locationId],
    };

    pBody = JSON.stringify(postBody);
    listLocations(pBody).then((data) => {
      const locations = data.locations.filter((l) => l.active === true).sort();
      setHousingUnits(locations);
      const housingUnits = { parentLocation, data: locations };
      this.setState({ huLoading: false, housingUnits });
    });
  };

  componentDidMount() {
    if (!POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
      this.onNewAnnouncementListener();
    }
    this.setGlobal({
      forceReRenderTheAuthContainer: () => {
        this.setState({ ...this.state });
      },
      hiddenBreadcrumbItems: [],
    });
  }

  handleLogInOut = () => {
    const { keycloak, auth0 } = this.props;
    sessionStorage.setItem('isLogoutInitiated', true);
    handleLogout(keycloak, auth0);
  };

  onRouteChanged() {
    if (this.global.openProfileView) {
      this.setGlobal({
        selectedUser: null,
        openProfileView: false,
      });
    }
  }

  setupCacheLocation = (key, reduxCallback, inAppCallback) => {
    try {
      let cachedLocation = this.userStorageService.getItem(key);
      if (cachedLocation) {
        cachedLocation = JSON.parse(cachedLocation);
        const isLocationValid = LOCATIONS.some((l) => l.locationId === cachedLocation.locationId && l.active);
        if (isLocationValid) {
          reduxCallback(cachedLocation);
          inAppCallback(cachedLocation);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (this.state.authorize) {
      return;
    }

    this.performAuthCheck();
  }

  performAuthCheck = async () => {
    const { keycloak, auth0 } = this.props;
    const authResponse = await handleAuthentication(keycloak, auth0);

    if (!authResponse.isAuthenticated) {
      return authResponse.redirectToLogin();
    }

    if (!authResponse.isAuthorized) {
      this.setState({ authorize: false });
      return;
    }

    setUserContext(authResponse.user);

    this.props.currentUser({
      userId: authResponse.user.personId,
      userName: authResponse.user.preferred_username,
    });

    this.fetchLocations();
    this.loggingService.logDeviceInfo({
      userName: authResponse.user.preferred_username,
      eventType: 'login',
      status: 'successful',
      userLocalTime: moment().format(),
      userLocationTime: null,
      kcAuthTime: moment.unix(authResponse.user.auth_time).utc().format(),
    });

    this.setState({ authorize: true });

    this.setGlobal({
      isSuperAdmin: authResponse.user.isSuperAdmin,
      isAttorney: authResponse.user.isAttorney,
      isFacilityAdmin: authResponse.user.isFacilityAdmin,
      isTeacher: authResponse.user.isTeacher,
      routes: this.getRoutes(authResponse.user),
    });

    if (authResponse.user.isAttorney) {
      this.props.history.push('/messages');
    }
  };

  getPathName = () => {
    if (this.props.location.search) {
      const query = this.props.location.search.split('&state=')[0];
      return this.props.location.pathname + query;
    }
    return this.props.location.pathname.split('&state=')[0];
  };

  getRoutes = ({ isSuperAdmin, isAttorney, isTeacher } = {}) => {
    if (this.global.cbacConfigFlag) {
      const routes = PermittedRoutes.filter(
        (item) =>
          (!item.roleTypes || item.roleTypes.find((role) => this.global.roles.includes(role))) &&
          (!item.permissions ||
            item.permissions.find((permission) => {
              //For Stack Permission
              if (Array.isArray(permission)) {
                return permission.every((p) =>
                  isPermitted(this.global.cbacConfigFlag, this.global.permissions, p, this.global.permissionIds)
                );
              }
              //Any Permission Available
              return isPermitted(this.global.cbacConfigFlag, this.global.permissions, permission, this.global.permissionIds);
            }))
      );
      return routes;
    }

    if (this.global.isSuperAdmin || isSuperAdmin) {
      return SuperAdminRoutes;
    }

    if (this.global.isAttorney || isAttorney) {
      return AttorneyRoutes;
    }

    if (this.global.isTeacher || isTeacher) {
      return TeacherRoutes;
    }

    return AdminRoutes;
  };

  isSelectedLocation = (location, selectedLocation, selectedHousingUnit) => {
    // if (selectedLocation) {
    //   if (location === selectedLocation.locationName.replace(/[^a-zA-Z0-9-_]/g, '')) {
    //     return true
    //   }
    // }
    // if (selectedHousingUnit) {
    //   if (location === selectedHousingUnit.locationName.replace(/[^a-zA-Z0-9-_]/g, '')) {
    //     return true
    //   }
    // }

    return true;
  };

  shouldShowLocationLocationPicker = () => {
    const routes = ['manage', 'messages', 'outcomes-and-progress', 'voiceCalls'];
    const path = this.props.location.pathname.split('/')[1];
    if (routes.indexOf(path) > -1) {
      return true;
    }
    return false;
  };

  showHousingUnitSelect = (route) => {
    const path = this.props.location.pathname.split('/')[1];
    if (route === 'messages') {
      return false;
    } else if (path.indexOf('outcomes-and-progress') > -1 && !this.props.selectedHousingUnit) {
      return false;
    }
    return true;
  };

  onNewAnnouncementCallback = ({ detail }) => {
    const { unreadCountByLocations } = this.props;
    const unreadCountByLocationIndex = unreadCountByLocations.findIndex((item) => item.locationKey === detail.locationId);
    if (unreadCountByLocationIndex > -1) {
      unreadCountByLocations[unreadCountByLocationIndex] = {
        locationKey: detail.locationId,
        totalUnreadCount: unreadCountByLocations[unreadCountByLocationIndex].totalUnreadCount + 1,
      };
    } else {
      unreadCountByLocations.push({
        locationKey: detail.locationId,
        totalUnreadCount: 1,
      });
    }
    this.props.setUnreadCountByLocation([...unreadCountByLocations]);
  };

  onNewAnnouncementListener = () => {
    const announcementElement = document.querySelector(`#${MessageService.ANNOUNCEMENT_ELEMENT}`);
    if (announcementElement) {
      announcementElement.addEventListener(MessageService.NEW_ANNOUNCEMENT, this.onNewAnnouncementCallback, false);
    }
  };

  SocketHook = () => {
    useStaffMessageSocket();
    useStaffRelationStatusChange();
    return null;
  };

  ApplicationLayout = () => {
    const { classes, keycloak, auth0 } = this.props;
    const tokenClaims = getTokenClaims(keycloak, auth0);
    const personId = tokenClaims?.personId;
    const { isFlagEnabled } = useUnleashHook(UnleashService.FLAGS.OPTIMIZE_SOCKET);
    if (!isFlagEnabled() && !POLL_CONFIG.USE_POLLING) {
      joinRoom(personId);
    }
    //INFO initilize the pendo
    usePendoInitialize({ locations: this.state.locations, tokenClaims });

    //INFO Initialize the SocketIO

    return (
      <Box className={classes.mainApplication}>
        <Box>
          {/* INFO SIDE MENU BAR */}
          {this.global.routes && (
            <SideMenu
              routes={this.global.routes}
              cbacConfigFlag={this.global.cbacConfigFlag}
              permissions={this.global.permissions}
              permissionIds={this.global.permissionIds}
              global={this.global}
              {...this.props}
            />
          )}
          {!POLL_CONFIG.USE_POLLING && <this.SocketHook />}
          {/* INFO HEADER */}
        </Box>
        <Box overflow="auto" flex={1} display="flex" flexDirection="column">
          <Box>
            <MainNav handleLogout={this.handleLogInOut} history={this.props.history} />
          </Box>
          <Box flex={1} display="flex" flexDirection="column">
            {/* MAIN  */}
            {/* INFO MAIN MENU*/}
            <Box className={classes.mainSection}>
              {this.global.routes && (
                <React.Fragment>
                  {/* TODO: Find a better way to handle keycloak query string */}
                  <Redirect exact from={this.props.location} to={this.getPathName()} />
                  <Switch>{renderRoutes(this.global.routes)}</Switch>
                </React.Fragment>
              )}
            </Box>
            <Box className={classes.footer} component="div" display="flex" justifyContent="space-between" width="100%">
              <Typography>
                <a target="_blank" href={`${this.BASE_URL}/copyrights.html`}>
                  Copyright
                </a>{' '}
                © {moment().format('YYYY')} Orijin
              </Typography>
              <Box>
                <ul>
                  <li>
                    <Typography>
                      <a target="_blank" href={`${this.BASE_URL}/terms-of-service.html`}>
                        Terms of Service
                      </a>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <a target="_blank" href={`${this.BASE_URL}/privacy-policy.html`}>
                        Privacy Policy
                      </a>
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  onClose = () => {
    if (this.snackBarTimeout) {
      clearTimeout(this.snackBarTimeout);
    }

    this.props.hideToast();
    this.snackBarTimeout = setTimeout(() => this.props.changeToastType(SNACK_BAR_SUCCESS), SNACK_BAR_TIMEOUT);
  };

  render() {
    const { keycloak, auth0 } = this.props;
    const isLogoutInitiated = !!sessionStorage.getItem('isLogoutInitiated');
    const logInOutText = 'Sign In Again';
    const authenticated = isAuthenticated(keycloak, auth0);

    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <IdleTimeout handleLogout={this.handleLogInOut} />
        <UnleashProvider flagName={UnleashService.FLAGS.BYPASS_HOME_PAGE}>
          <MessagingRedirect
            isMessagingOnly={this.global.isMessagingOnly}
            history={this.props.history}
            location={this.props.location}
          />
        </UnleashProvider>
        <Box width="100%">
          {this.global.staffProfileID && this.global.isStaffProfileOpen && (
            <StaffProfile
              staffData={{
                personId: this.global.staffProfileID,
              }}
              staffRole={this.global.staffRole}
              activeLocation={this.global.staffSelectedLocation}
              isOpen={this.global.isStaffProfileOpen}
              refreshStaffList={this.global.refreshStaffList}
              history={this.props.history}
            />
          )}

          <Loader isLoading={this.state.locationLoading || this.state.huLoading} />
          <SessionOut />
          {/* AUTHENTICATED AND AUTHORIZED CASE */}
          <CbacContainer authorize={this.state.authorize} getRoutes={this.getRoutes}>
            <this.ApplicationLayout />
          </CbacContainer>
          {/* AUTHENTICATED BUT NOT AUTHORIZED CASE */}
          {authenticated && !this.state.authorize && (
            <SessionExpire
              errorMessage={'User Is Not Authorized.'}
              logoutText={logInOutText}
              onClick={this.handleLogInOut}
            />
          )}
          {/* NOT AUTHENTICATED CASE */}
          {!isLogoutInitiated && !authenticated && (
            <SessionExpire
              errorMessage={'Your session has expired.'}
              logoutText={logInOutText}
              onClick={this.handleLogInOut}
            />
          )}
          <Snackbar
            open={this.props.toast ? true : false}
            autoHideDuration={SNACK_BAR_TIMEOUT}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={this.onClose}
          >
            <MuiAlert severity={this.props.toastType} elevation={6} variant="filled">
              {this.props.toast}
            </MuiAlert>
          </Snackbar>
        </Box>
      </Suspense>
    );
  }
}

AuthContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state.app,
    unreadCountByLocations: state.rsMessageModuleStore.unreadCountByLocations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideToast: () => dispatch(hideToast()),
    changeToastType: (toastType) => dispatch(changeToastType(toastType)),
    setHousingUnit: (hu) => dispatch(setHousingUnit(hu)),
    selectLocation: (location) => dispatch(selectLocation(location)),
    currentUser: (user) => dispatch(currentUser(user)),
    setUnreadCountByLocation: (location) => dispatch(setUnreadCountByLocation(location)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withAuth0(withKeycloak(withRouter(AuthContainer)))));
