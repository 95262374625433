import React, { Component, Fragment } from 'reactn';
import { withStyles, CircularProgress, Box } from '@material-ui/core';
import moment from 'moment';

import styles from './index.styles';
import DateSeparator from '../date-separator';
import {
  fetchMessages,
  markMessagesRead,
  markAnnouncementsRead,
  deleteAnnouncement,
  getAchievementDetail,
  fetchMessagesFromCommunication,
} from '../../../../../util/APIUtils';
import {
  MESSAGING_POLL_INTERVAL,
  MESSAGE_CONTAINER_FETCH_MSG_ENUM,
  POLL_CONFIG,
  USER_CONTEXT,
} from '../../../../../constants';
import ViewAnnouncementDetail from '../announcement-detail';
import AnnouncementHideConfirmation from '../announcement-hide-confirmation';
import { parseTimeToNewZone } from '../../../../../components/users/learner/transcript/utils';
import { connect } from 'react-redux';
import { isObjEmpty } from '../../../../../util/Helpers';
import SingleMessage from '../v2/single-message/single-message';
import SingleAnnouncement from '../v2/single-announcement/single-announcement';
import { MessageService } from '../../../../services/message.service';
import { getMessagingSelectedTabType } from '../../../../services/utilty';
import { ANNOUNCEMENT, DIRECT_MESSAGE } from '../../../../services/constants';
import { withUnleashClient } from '../../../../core/unleash';
import { UnleashService } from '../../../../services';
import store from '../../../../core/store';
import {
  rsSetAnnouncements,
  rsSetUnReadMsgAnnouCount,
  setUnreadCountByLocation,
} from '../../../../core/store/reducers/messageModuleReducer';

class MessagesContainer extends Component {
  state = {
    messagesArray: [],
    messages: {
      read: new Map(),
      unread: new Map(),
    },
    isLoading: false,
    isMessageLoading: false,
    lastMessage: null,
    isObserving: false,
    shouldNotScroll: false,
    isLazyLoading: false,
    firstLoad: true,
    isOpenDetailDialog: false,
    selectedAnnouncementDetail: null,
    isHideConfirmDialogOpen: false,
    selectedAnnouncementId: null,
    isPermitted: true,
    isSenderIdFlagEnabled: false,
    isMarkReadFlagEnabled: false,
  };
  getMsgTimeoutRef = null;
  hitPollApi = true;
  controller = new AbortController();
  msgService = new MessageService();

  componentDidMount() {
    this.toggleFeatureFlag();
    const {
      selectedContact,
      rsMessageModuleStore: { rsActiveThread },
    } = this.props;
    this.scrollObserver = new IntersectionObserver(this.handleObserver, {
      root: this.scrollWindow,
    });
    if (POLL_CONFIG.USE_POLLING) {
      this.pollGetMessages(selectedContact);
    } else {
      this.getMessages(selectedContact, true);
      this.onNewMessageEvent(selectedContact);
    }
  }

  isCommunicationBreakFeatureFlagEnabled = () => {
    const unleashClient = this.props.unleashClient();
    return unleashClient.isEnabled(UnleashService.FLAGS.COMMUNICATION_BREAK_FIX);
  };

  toggleFeatureFlag() {
    const unleashClient = this.props.unleashClient();
    this.setState({
      isSenderIdFlagEnabled: unleashClient.isEnabled(UnleashService.FLAGS.SENDER_ID_OVER_SENDER_NAME),
      isMarkReadFlagEnabled: unleashClient.isEnabled(UnleashService.FLAGS.MARK_READ),
    });
  }

  get messageElement() {
    return document.querySelector(`#${MessageService.MESSAGE_ELEMENT}`);
  }
  componentWillUnmount() {
    clearInterval(this._intervalId);
    if (this.messageElement) {
      this.messageElement.removeEventListener(MessageService.NEW_MSG_EVENT, this.newMessageHandler, false);
    }

    this.scrollObserver.disconnect();
  }

  newMessageHandler = ({ detail }) => {
    const unleashClient = this.props.unleashClient();
    const isFeatureFlagEnabled = unleashClient.isEnabled(UnleashService.FLAGS.OPTIMIZE_SOCKET);
    const { selectedContact } = this.props;
    const { threadId: selectedThreadId } = selectedContact;
    const { threadId: socketThreadId, body, resetMsg } = detail;
    if (isFeatureFlagEnabled) {
      if (selectedThreadId === socketThreadId) {
        if (resetMsg) {
          this.getMessages(selectedContact);
        } else {
          this.prepareAndSetMessages([
            {
              ...body,
              createdDate: new Date().toISOString(),
              recipientPersonId: body.recipientPersonId,
              senderPersonId: body.senderPersonId,
              body: body.clearBody,
              messageId: body.messageId,
              threadId: socketThreadId,
              recordStatus: true,
            },
          ]);
        }
      }
    } else {
      if (this.getMsgTimeoutRef) {
        clearTimeout(this.getMsgTimeoutRef);
      }
      if (selectedThreadId === socketThreadId) {
        this.getMsgTimeoutRef = setTimeout(() => {
          this.getMessages(selectedContact);
        }, MessageService.SOCKET_DEBOUNCE_INTERVAL);
      }
    }
  };

  onNewMessageEvent = () => {
    const { parentComponent } = this.props;
    if (this.isCommunicationBreakFeatureFlagEnabled()) {
      if (parentComponent !== MESSAGE_CONTAINER_FETCH_MSG_ENUM.COMMUNICATION) {
        document
          .querySelector(`#${MessageService.MESSAGE_ELEMENT}`)
          .addEventListener(MessageService.NEW_MSG_EVENT, this.newMessageHandler, false);
      }
      return;
    }

    //TODO: FEATURE FLAG CLEANUP
    document
      .querySelector(`#${MessageService.MESSAGE_ELEMENT}`)
      .addEventListener(MessageService.NEW_MSG_EVENT, this.newMessageHandler, false);
  };

  componentWillUpdate(_, nextState) {
    if (this.state.messagesArray.length !== nextState.messagesArray.length) {
      this.scrollSnapshot = this.scrollWindow.scrollHeight - this.scrollWindow.scrollTop;
    }
  }

  handleGlobalUnreadCount = (selectedThread, unreadCountByLocations) => {
    unreadCountByLocations = unreadCountByLocations.filter(
      (item) => item.locationKey !== selectedThread.locationId
    );
    if (selectedThread.totalUnreadCount > 0) {
      unreadCountByLocations[selectedThread.locationId] = {
        totalUnreadCount: selectedThread.totalUnreadCount,
      };
    }
    store.dispatch(setUnreadCountByLocation(unreadCountByLocations));
  };
  markAnnouncementRead = (id) => {
    let { rsUnreadMsgAnnoCountObj, rsAnnouncementsThread, unreadCountByLocations } = this.props.rsMessageModuleStore;
    const { totalUnreadAnnouncementCount } = rsUnreadMsgAnnoCountObj;
    const selectedAnnouncementThreadIndex = rsAnnouncementsThread.findIndex((thread) => thread.id == id);

    if (!rsAnnouncementsThread[selectedAnnouncementThreadIndex]['readDate']) {
      rsAnnouncementsThread[selectedAnnouncementThreadIndex]['readDate'] = new Date().toISOString();
      if (!POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
        this.handleGlobalUnreadCount(rsAnnouncementsThread[selectedAnnouncementThreadIndex], unreadCountByLocations);
      }

      store.dispatch(
        rsSetUnReadMsgAnnouCount({
          rsUnreadMsgAnnoCountObj: {
            ...rsUnreadMsgAnnoCountObj,
            totalUnreadAnnouncementCount: totalUnreadAnnouncementCount - 1,
          },
        })
      );
      store.dispatch(rsSetAnnouncements({ rsAnnouncementsThread }));
    }
  };

  async componentWillReceiveProps(nextProps) {
    const { selectedContact = {} } = this.props;
    const newThread =
      selectedContact.threadId !== nextProps.rsMessageModuleStore.rsSelectedContact.threadId ||
      selectedContact.id !== nextProps.rsMessageModuleStore.rsSelectedContact.id;
    if (newThread) {
      this.firstMessageRef = null;
      this.firstMessage = null;
      this.elToScroll = null;
      this.initialScrollRef = null;
      this.scrollSnapshot = null;

      this.controller.abort();
      this.controller = new AbortController();

      clearInterval(this._intervalId);
      this.scrollObserver.disconnect();
      this.setState({
        firstLoad: true,
        isObserving: false,
        lastMessage: null,
        shouldNotScroll: false,
        messagesArray: [],
        messages: {
          read: new Map(),
          unread: new Map(),
        },
        isMessageLoading: false,
        isPermitted: true,
      });
      await this.getMessages(nextProps.selectedContact, true);
    }

    if (nextProps.isMessageSending && this.props.isMessageSending !== nextProps.MessageSending) {
      if (this.hitPollApi) {
        await this.getMessages(selectedContact);
      }
    }
    if (!newThread && nextProps.eTag !== this.props.eTag) {
      await this.getMessages(selectedContact);
    }
  }

  componentDidUpdate(_, prevState) {
    try {
      if (this.state.messagesArray.length !== prevState.messagesArray.length) {
        const { selectedContact, parentComponent } = this.props;
        const datesOfUnreadMsgs = Array.from(this.state.messages.unread.keys());
        if (datesOfUnreadMsgs.length > 0 && parentComponent !== MESSAGE_CONTAINER_FETCH_MSG_ENUM.COMMUNICATION) {
          if (selectedContact.threadId) {
            markMessagesRead(selectedContact.threadId, this.state.lastMessage.messageId);
          } else if (selectedContact.id) {
            if (this.state.isMarkReadFlagEnabled) {
              this.markAnnouncementRead(this.state.lastMessage.announcementId);
            }
            markAnnouncementsRead(this.state.lastMessage.announcementId);
          }
        }

        if (this.scrollSnapshot && this.state.shouldNotScroll) {
          this.scrollWindow.scrollTop = this.scrollWindow.scrollHeight - this.scrollSnapshot;
        }

        if (this.state.firstLoad && this.initialScrollRef && !this.state.shouldNotScroll) {
          this.initialScrollRef.scrollIntoView();
          this.setState({
            firstLoad: false,
          });
        } else if (this.elToScroll && !this.state.shouldNotScroll) {
          this.elToScroll.scrollIntoView();
        } else {
          this.setState({
            shouldNotScroll: false,
            isLazyLoading: false,
          });
        }

        if (!this.state.isObserving && !!this.firstMessage && !!this.firstMessageRef) {
          this.setState({
            isObserving: true,
          });
          setTimeout(() => {
            this.scrollObserver.observe(this.firstMessageRef);
          }, 2000);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleObserver = async (entries, observer) => {
    const { selectedContact } = this.props;

    const intersectingEl = entries[0];
    const firstMessage = this.state.messagesArray[0];
    if (intersectingEl.isIntersecting) {
      this.setState({ isLazyLoading: true });
      observer.unobserve(intersectingEl.target);

      let lazyLoadedMsgs = [];

      if (isObjEmpty(selectedContact)) {
        if (selectedContact.threadId) {
          lazyLoadedMsgs = await this.getMessageWrapper({
            threadKey: selectedContact.threadId,
            beforeId: firstMessage.messageId,
            limit: 30,
            signal: this.controller.signal,
          });
        }
      }

      if (lazyLoadedMsgs) {
        if (lazyLoadedMsgs.length > 0) {
          const transformedMessages = this.transformMessages(lazyLoadedMsgs, true);
          const newMessages = this.setMessages(transformedMessages, true);
          this.setState((prevState) => ({
            messages: newMessages,
            messagesArray: [...lazyLoadedMsgs, ...prevState.messagesArray],
            isObserving: false,
            shouldNotScroll: true,
          }));
        } else {
          this.setState({
            isObserving: false,
            isLazyLoading: false,
          });
        }
      }
    }
  };

  setMessages = (transformedMessages, isObserver) => {
    const readMessages = new Map(this.state.messages.read);
    const unreadMessages = new Map(this.state.messages.unread);

    let newReadMessages = new Map();
    let newUnreadMessages = new Map();

    Array.from(transformedMessages.read.keys()).map((msgSection) => {
      const existingMessages = readMessages.has(msgSection) ? readMessages.get(msgSection) : [];

      if (isObserver) {
        const newMessages = [...transformedMessages.read.get(msgSection), ...existingMessages];
        newReadMessages.set(msgSection, newMessages);
        readMessages.delete(msgSection);
      } else {
        const newMessages = [...existingMessages, ...transformedMessages.read.get(msgSection)];
        newReadMessages.set(msgSection, newMessages);
        readMessages.delete(msgSection);
      }
    });

    Array.from(transformedMessages.unread.keys()).map((msgSection) => {
      const existingMessages = unreadMessages.has(msgSection) ? unreadMessages.get(msgSection) : [];
      if (isObserver) {
        const newMessages = [...transformedMessages.unread.get(msgSection), ...existingMessages];
        newUnreadMessages.set(msgSection, newMessages);
        unreadMessages.delete(msgSection);
      } else {
        const newMessages = [...existingMessages, ...transformedMessages.unread.get(msgSection)];
        newUnreadMessages.set(msgSection, newMessages);
        unreadMessages.delete(msgSection);
      }
    });

    let newMapReadArray;
    let newMapUnreadArray;

    if (isObserver) {
      newMapReadArray = [...newReadMessages, ...readMessages];
      newMapUnreadArray = [...newUnreadMessages, ...unreadMessages];
    } else {
      newMapReadArray = [...readMessages, ...newReadMessages];
      newMapUnreadArray = [...unreadMessages, ...newUnreadMessages];
    }

    return {
      read: new Map(newMapReadArray),
      unread: new Map(newMapUnreadArray),
    };
  };

  /**
   * @name getMessageWrapper
   * @param {object} lazyLoadingData
   * @desc Fetch's messages w.r.t to parent calling component.
   * @return {Promise}
   */
  getMessageWrapper = (lazyLoadingData) => {
    const { parentComponent } = this.props;
    switch (parentComponent) {
      case MESSAGE_CONTAINER_FETCH_MSG_ENUM.COMMUNICATION:
        const requstParam = {
          userAgentInfo: window.navigator.userAgent,
        };
        return fetchMessagesFromCommunication(requstParam, lazyLoadingData);
      default:
        return fetchMessages(lazyLoadingData);
    }
  };

  /**
   * @name parseTimeToFacilityTimezone
   * @param {array} messages
   * @desc Parses message w.r.t facility timezone.
   * @return {void}
   */
  parseTimeToFacilityTimezone = (messages) => {
    return (messages || []).map((message) => {
      message['createdDate'] = parseTimeToNewZone(message.createdDate, this.props.timezoneName, 'MMM D YYYY hh:mm A');
      if (message.readDate) {
        message['readDate'] = parseTimeToNewZone(message.readDate, this.props.timezoneName, 'MMM D YYYY hh:mm A');
      }
      return message;
    });
  };
  prepareAndSetMessages = (messages) => {
    const { lastMessage } = this.state;
    messages = this.parseTimeToFacilityTimezone(messages);
    this.hitPollApi = true;
    if (messages) {
      if (messages.length > 0) {
        const transformedMessages = this.transformMessages(messages);
        const newMessages = this.setMessages(transformedMessages);

        this.setState(
          (prevState) => {
            return {
              lastMessage: messages[messages.length - 1] || lastMessage,
              messages: newMessages,
              messagesArray: [...prevState.messagesArray, ...messages],
              isMessageLoading: false,
              isPermitted: true,
            };
          },
          () => {
            if (this.props.setMessageSending) {
              this.props.setMessageSending(false);
            }
            if (this.props.setLimitError) {
              this.props.setLimitError(true);
            }
          }
        );
      } else {
        this.setState({
          isMessageLoading: false,
          isPermitted: true,
        });
      }
    } else {
      throw new Error('ABORTED');
    }
  };
  getMessages = async (selectedContact, isFirstRequest) => {
    const { lastMessage } = this.state;
    const threadKey = selectedContact.threadId || selectedContact.locationId;
    const contactAfterId = isFirstRequest ? undefined : lastMessage && lastMessage.messageId;
    const announcementAfterId = isFirstRequest ? undefined : lastMessage && lastMessage.announcementId;
    let messages = [];
    this.hitPollApi = false;
    if (isFirstRequest) {
      this.setState({
        isMessageLoading: true,
        isPermitted: true,
      });
    }
    try {
      if (isObjEmpty(selectedContact)) {
        if (selectedContact.threadId) {
          messages = await this.getMessageWrapper({
            threadKey,
            afterId: contactAfterId,
            signal: this.controller.signal,
          });
        } else if (selectedContact.id && isFirstRequest) {
          messages = await this.msgService.getAnnouncementData(this.state.messages, selectedContact);
        }
      }
      this.prepareAndSetMessages(messages);
    } catch (error) {
      //console.log(error);
      const transformedMessages = this.transformMessages([]);
      const newMessages = this.setMessages(transformedMessages);
      this.setState({
        isMessageLoading: false,
        messages: newMessages,
        isPermitted: error.status !== 403,
      });
    }
  };

  transformMessages = (messages, isObserver) => {
    const { selectedContact } = this.props;

    const { messages: stateMessages, isSenderIdFlagEnabled } = this.state;

    const hasUnread = Array.from(stateMessages.unread.keys()).length > 0;

    return messages.reduce(
      (acc, msg) => {
        const isToday = moment(msg.createdDate).isSame(moment(), 'day');
        const isYesterday = moment(msg.createdDate).isSame(moment().subtract(1, 'days'), 'day');

        const formattedDate = moment(msg.createdDate).format('MMMM D, YYYY');

        let date;

        if (isYesterday) {
          date = 'Yesterday';
        } else if (isToday) {
          date = 'Today';
        } else {
          date = formattedDate;
        }

        let isMyMessage = null;

        if (isSenderIdFlagEnabled) {
          isMyMessage = selectedContact.threadId
            ? msg.senderPersonId !== selectedContact.contactId
            : msg.senderUserName === USER_CONTEXT.preferred_username;
        } else {
          isMyMessage = selectedContact.threadId
            ? msg.senderPersonName !== selectedContact.contactName
            : msg.senderUserName === USER_CONTEXT.preferred_username;
        }

        /** this condition set the messages in READ or UNREAD categories
         * on following conditions
         * 1. if message has read date and there are no unread messages in the list or message are lazy
         *  loaded then they are read messages.
         * 2. if message does not have read but it is sent by the user himself and there are no messages
         *  in the list or messages are lazy loaded then they are read messages
         */
        if ((msg.readDate || (!!!msg.readDate && isMyMessage)) && (isObserver || !hasUnread)) {
          acc.read.set(date, acc.read.has(date) ? [...acc.read.get(date), msg] : [msg]);
        } else {
          acc.unread.set(date, acc.unread.has(date) ? [...acc.unread.get(date), msg] : [msg]);
        }

        return acc;
      },
      {
        read: new Map(),
        unread: new Map(),
      }
    );
  };

  pollGetMessages = async (selectedContact) => {
    this.setState({
      isMessageLoading: true,
      isPermitted: true,
    });
    try {
      await this.getMessages(selectedContact, true);
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }
      this._intervalId = setInterval(async () => {
        try {
          if (this.hitPollApi) {
            await this.getMessages(selectedContact);
          }
        } catch (error) {}
      }, MESSAGING_POLL_INTERVAL);
    } catch (error) {}
  };

  getAnnouncementDetail = async (announcementDetail) => {
    const { selectedContact } = this.props;
    this.setGlobal({ ...this.global, isLoading: true });
    const detail = await getAchievementDetail(announcementDetail.announcementId, selectedContact.locationId);
    detail.readAnnouncementUsersList.forEach((readAnnouncementUsers) => {
      readAnnouncementUsers.read = true;
    });
    const usersList = [...detail.readAnnouncementUsersList, ...detail.unreadAnnouncementUsersList];
    usersList.sort((val1, val2) => {
      if (val1.read && val1.userName.toLowerCase() < val2.userName.toLowerCase()) return -1;
      else if (!val1.read && val1.userName.toLowerCase() > val2.userName.toLowerCase()) return 1;
      else return 0;
    });
    return {
      announcementUsersList: usersList,
      readCount: detail.totalReadAnnouncementCount,
      unreadCount: detail.totalUnreadAnnouncementCount,
    };
  };

  onDetailClick = async (announcementDetail) => {
    const detail = await this.getAnnouncementDetail(announcementDetail);
    await this.setState({
      ...this.state,
      isOpenDetailDialog: true,
      selectedAnnouncementDetail: { ...announcementDetail, ...detail },
    });
    this.setGlobal({ ...this.global, isLoading: false });
  };

  closeDetailModal = () => {
    this.setState({ ...this.state, isOpenDetailDialog: false });
  };

  openConfirmationModal = (announcementId) => {
    this.setState({ ...this.state, isHideConfirmDialogOpen: true, selectedAnnouncementId: announcementId });
  };

  closeHideConfirmationModal = async (confirmed) => {
    if (confirmed) {
      const deleteResponse = await deleteAnnouncement(this.state.selectedAnnouncementId);
      if (deleteResponse) {
        let index = -1;
        for (const messageList of this.state.messages.read.values()) {
          index = messageList.findIndex((x) => x.announcementId === this.state.selectedAnnouncementId);
          if (index === -1) {
            continue;
          }

          this.removeMessage(messageList, index);
          break;
        }

        if (index === -1) {
          for (const messageList of this.state.messages.unread.values()) {
            index = messageList.findIndex((x) => x.announcementId === this.state.selectedAnnouncementId);
            if (index === -1) {
              continue;
            }
            this.removeMessage(messageList, index);
            break;
          }
        }
      }
    }
    await this.setState({
      ...this.state,
      isHideConfirmDialogOpen: false,
      selectedAnnouncementId: null,
    });
  };

  removeMessage = (messageList, index) => {
    messageList.splice(index, 1);
  };

  renderMessages = (messages) => {
    const { selectedContact, timezoneName } = this.props;
    const { rsActiveThread: activeThread } = this.props.rsMessageModuleStore;

    const lastReadMsgsSectionIndex = Array.from(messages.read.keys()).length - 1;
    const lastUnreadMsgsSectionIndex = Array.from(messages.unread.keys()).length - 1;

    const getMessageElement = ({ msg, idx, dateKey, isLast, isFirst, msgType }) => {
      const isRead = msgType === 'read';
      const isFirstElement = idx === 0;

      const hasRead = Array.from(messages.read.keys()).length > 0;
      const hasUnread = Array.from(messages.unread.keys()).length > 0;

      const isTodayAlreadyPresent = dateKey === 'Today' && messages.read.has('Today');

      if (hasRead && isRead && isFirst) {
        this.firstMessage = msg;
      } else if (!hasRead && !isRead && isFirst) {
        this.firstMessage = msg;
      }

      return (
        <Fragment key={`${msg.messageId || msg.announcementId}-${Math.random()}`}>
          {!isRead && isTodayAlreadyPresent
            ? null
            : isFirstElement && <DateSeparator text={`${dateKey} (${timezoneName})`} />}
          {getMessagingSelectedTabType(selectedContact) === DIRECT_MESSAGE && (
            <SingleMessage
              key={`${msg.messageId}-${Math.random()}`}
              parentComponent={this.props.parentComponent}
              cbacConfigFlag={this.global.cbacConfigFlag}
              permissions={this.global.permissions}
              messageRef={(el) => {
                if (hasUnread) {
                  if (isLast && !isRead) {
                    this.elToScroll = el;
                  }
                } else {
                  if (isLast && isRead) {
                    this.elToScroll = el;
                  }
                }

                if (!this.initialScrollRef) {
                  if (hasRead) {
                    if (isLast && isRead) {
                      this.initialScrollRef = el;
                    }
                  } else if (hasUnread) {
                    if (isFirst && !isRead) {
                      this.initialScrollRef = el;
                    }
                  }
                }

                if (hasRead && isRead && isFirst) {
                  this.firstMessageRef = el;
                } else if (!hasRead && !isRead && isFirst) {
                  this.firstMessageRef = el;
                }
              }}
              selectedContact={selectedContact}
              loggedInUserName={USER_CONTEXT.preferred_username}
              {...msg}
              activeThread={activeThread}
              onDetailClick={this.onDetailClick}
              openConfirmationModal={this.openConfirmationModal}
            />
          )}

          {getMessagingSelectedTabType(selectedContact) === ANNOUNCEMENT && (
            <SingleAnnouncement
              key={`${msg.messageId}-${Math.random()}`}
              parentComponent={this.props.parentComponent}
              cbacConfigFlag={this.global.cbacConfigFlag}
              permissions={this.global.permissions}
              messageRef={(el) => {
                if (hasUnread) {
                  if (isLast && !isRead) {
                    this.elToScroll = el;
                  }
                } else {
                  if (isLast && isRead) {
                    this.elToScroll = el;
                  }
                }

                if (!this.initialScrollRef) {
                  if (hasRead) {
                    if (isLast && isRead) {
                      this.initialScrollRef = el;
                    }
                  } else if (hasUnread) {
                    if (isFirst && !isRead) {
                      this.initialScrollRef = el;
                    }
                  }
                }

                if (hasRead && isRead && isFirst) {
                  this.firstMessageRef = el;
                } else if (!hasRead && !isRead && isFirst) {
                  this.firstMessageRef = el;
                }
              }}
              selectedContact={selectedContact}
              loggedInUserName={USER_CONTEXT.preferred_username}
              {...msg}
              activeThread={activeThread}
              onDetailClick={this.onDetailClick}
              openConfirmationModal={this.openConfirmationModal}
            />
          )}
        </Fragment>
      );
    };

    const readMessages = Array.from(messages.read.keys()).map((dateKey, index) => {
      const currentMsgsGroup = messages.read.get(dateKey);
      return currentMsgsGroup.map((msg, idx) => {
        const isLast = index === lastReadMsgsSectionIndex && idx === currentMsgsGroup.length - 1;
        const isFirst = index === 0 && idx === 0;

        return getMessageElement({ msg, idx, dateKey, isLast, isFirst, msgType: 'read' });
      });
    });

    const unreadMessages = Array.from(messages.unread.keys()).map((dateKey, index) => {
      const currentMsgsGroup = messages.unread.get(dateKey);

      return currentMsgsGroup.map((msg, idx) => {
        const isLast = index === lastUnreadMsgsSectionIndex && idx === currentMsgsGroup.length - 1;
        const isFirst = index === 0 && idx === 0;

        return getMessageElement({ msg, idx, dateKey, isLast, isFirst, msgType: 'unread' });
      });
    });

    if (Array.from(messages.unread.keys()).length > 0) {
      unreadMessages.unshift(<DateSeparator key={-1} text="Unread" />);
    }

    return [...readMessages, ...unreadMessages];
  };

  render() {
    const { rsActiveThread: activeThread, rsSelectedContact: selectedContact } = this.props.rsMessageModuleStore;
    const location = this.props.selectedLocation;
    const { classes } = this.props;
    const { messages, isMessageLoading, isLazyLoading, isPermitted } = this.state;
    const noMessagesAvailable = messages && messages.read.size === 0 && messages.unread.size === 0;
    const isAttorney = this.global.isAttorney;
    return (
      <div
        ref={(el) => (this.scrollWindow = el)}
        style={{
          height: this.props.height,
        }}
        className={classes.messages}
      >
        {isMessageLoading || noMessagesAvailable ? (
          <div className={classes.loadingWrapper}>
            <Box className={classes.center}>{isMessageLoading && <CircularProgress size={30} />}</Box>
            {!isMessageLoading && noMessagesAvailable && (
              <span>
                {!isPermitted
                  ? `You cannot view the ${selectedContact.contactId ? 'thread' : 'announcements'} at this location`
                  : isAttorney
                  ? `No Messages Available`
                  : `No ${selectedContact.contactId ? 'Messages' : 'Announcements'} Available`}
              </span>
            )}
          </div>
        ) : (
          <>
            <div
              key={-2}
              style={{
                display: activeThread && isLazyLoading ? 'block' : 'none',
                paddingTop: 5,
              }}
            >
              <Box className={classes.center}>
                <CircularProgress size={30} />
              </Box>
            </div>
            {this.renderMessages(messages)}
            <ViewAnnouncementDetail
              announcementDetail={this.state.selectedAnnouncementDetail}
              closeModal={this.closeDetailModal}
              isDialogOpen={this.state.isOpenDetailDialog}
              location={location}
            />
            <AnnouncementHideConfirmation
              isDialogOpen={this.state.isHideConfirmDialogOpen}
              closeModal={this.closeHideConfirmationModal}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    rsMessageModuleStore: state.rsMessageModuleStore,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withUnleashClient(MessagesContainer)));
