import moment from 'moment';
import { getUnreadMessagesByLocationIds, sisenseLogout } from './APIUtils';
import { MessageService } from '../v2/services/message.service';
import { handleLogout as handleAuthLogout } from '../v2/core/auth/utils';

const PASSWORD_LENGTH = 8;
export const VALID_DOB_FORMAT = 'MM-DD-YYYY';

export function formatDate(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + year;
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const validateURL = (value = '') => {
  const re = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  return re.test(value);
};

export const validateNumber = value => {
  const re = /^\d+$/;
  return re.test(value);
};

export const validateAlpaNumUnderDash = value => {
  const re = /^([a-zA-Z0-9 _-]+)$/;
  return re.test(value);
};

export function autogeneratePassword() {
  var lowerAlphaChars = 'abcdefghjkmnpqrstuvwxyz'; // exclude i, l, o
  var upperAlphaChars = 'ABCDEFGHJKMNPQRSTUVWXYZ'; // exclude I, L, O
  var numericChars = '23456789'; // exclude 0, 1
  var specialChars = '!@#$%^*?';

  var password = '';

  // add letters
  for (var i = 0; i < PASSWORD_LENGTH - 3; i++)
    password += lowerAlphaChars.charAt(Math.floor(Math.random() * lowerAlphaChars.length));
  password += upperAlphaChars.charAt(Math.floor(Math.random() * upperAlphaChars.length));
  // add number
  password += numericChars.charAt(Math.floor(Math.random() * numericChars.length));
  // add special character
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
  // shuffle the password
  return shuffleString(password);
}

function shuffleString(string) {
  var parts = string.split('');
  for (var i = parts.length; i > 0; ) {
    var random = parseInt(Math.random() * i);
    var temp = parts[--i];
    parts[i] = parts[random];
    parts[random] = temp;
  }
  return parts.join('');
}

export function validatePassword(password, shouldShowAll) {
  let errors = [];
  if (password || shouldShowAll) {
    // check password length
    if (password.length < PASSWORD_LENGTH) {
      errors.push(`Passwords must be at least ${PASSWORD_LENGTH} characters long.`);
    }

    // check for password digit and special character
    var regExp = /(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*?])./;
    var validPassword = regExp.test(password);
    if (!validPassword) {
      errors.push('Passwords must include at least 1 capital letter, 1 digit, and 1 special character (! @ # $ % ^ * ?).');
    }

    var excludeCharacterRegex = /([&<>=])/;
    if (excludeCharacterRegex.test(password)) {
      errors.push('Password must not contain special characters like & < > =');
    }
  }

  return errors;
}

export function validateDateOfBirth(dateOfBirth) {
  let errors = [];
  const VALID_DOB_FORMAT = 'MM/DD/YYYY';
  const currentDate = moment();
  if (!dateOfBirth || !moment(dateOfBirth, VALID_DOB_FORMAT).isValid()) {
    errors.push('Please provide a date of birth value in the format MM-DD-YYYY with no punctuation.');
  }
  if (dateOfBirth && moment(dateOfBirth, VALID_DOB_FORMAT).diff(currentDate, 'days') >= 0) {
    errors.push("Date of birth should be before today's date.");
  }

  return errors;
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

export const handleLogout = props => {
  const { keycloak, global } = props;
  sessionStorage.clear();
  sessionStorage.setItem('isLogoutInitiated', true);
  if (global.sisenseInitialize) sisenseLogout(); // logout the sisense user also
  handleAuthLogout(keycloak);
};

export function isObjEmpty(obj = {}) {
  return Boolean(Object.keys(obj).length);
}

const getLocationIds = (data = []) => {
  let locationIds = [];
  (data || []).forEach(location => {
    locationIds.push(location.locationId);
    location.children.forEach(housingUnit => {
      locationIds.push(housingUnit.locationId);
    });
  });
  return locationIds;
};

const getUnreadMessAnnBylocationId = async (commaSeparatedLocationIds, unreadCountData) => {
  let _success = null;
  if (unreadCountData) {
    _success = unreadCountData;
  } else {
    _success = await getUnreadMessagesByLocationIds(commaSeparatedLocationIds);
  }
  let locationMapper = {};
  if (_success && _success.length) {
    _success.forEach(({ locationKey, totalUnreadCount }) => {
      locationMapper[locationKey] = totalUnreadCount;
    });
  }
  return locationMapper;
};

const findTotalUnreadCount = (locationMapper, inDataArr = []) => {
  const newArray = inDataArr.slice();
  return (newArray || []).map(location => {
    location.totalUnreadCount = locationMapper[location.locationId] || 0;
    (location.children || []).map(housingUnit => {
      housingUnit.totalUnreadCount = locationMapper[housingUnit.locationId] || 0;
      if (housingUnit.totalUnreadCount) {
        // INFO If a facility has subordinate housing units with unreadCount then, display the Red carat on the facility name
        location.totalUnreadCount = (location.totalUnreadCount || 0) + housingUnit.totalUnreadCount;
      }
      return location;
    });
    return location;
  });
};

export const getUnreadCountFromApi = async (locationArr = []) => {
  if (locationArr.length) {
    let locationIds = getLocationIds(locationArr); // get all the locationIds array
    const locationMapper = await getUnreadMessAnnBylocationId(locationIds.join(',')); // get the unread count for all the locationIds
    const locationDataWithUnreadCount = findTotalUnreadCount(locationMapper, locationArr); // add the unread count to the locationArr
    return locationDataWithUnreadCount;
  }
};

export const getUnreadCountData = async (locationArr, unreadCountData) => {
  if (locationArr.length) {
    let locationIds = getLocationIds(locationArr); // get all the locationIds array
    const locationMapper = await getUnreadMessAnnBylocationId(locationIds.join(','), unreadCountData); // get the unread count for all the locationIds
    const locationDataWithUnreadCount = findTotalUnreadCount(locationMapper, locationArr); // add the unread count to the locationArr
    return locationDataWithUnreadCount;
  }
};

export const getUserIdFromURL = urlKeyword => {
  const path = window.location.href;
  if (path) {
    const regex = new RegExp(`${urlKeyword}/(\\d+)`);
    const match = path.match(regex);
    return (match && match[1]) || '';
  }
};

export const columnSortByOrderDirection = (tableColumns, orderBy, orderDirection) => {
  if (orderBy && orderDirection) {
    tableColumns = tableColumns
      .map((item, i) => {
        if (item.field !== orderBy.field) {
          item.defaultSort = '';
        } else {
          item.defaultSort = orderDirection;
        }
        return item;
      })
      .slice();
  }
};

/**
 * emitMessageEvent
 * @param {*} data
 * @return {void}
 */
export const emitMessageEvent = (data) => {
  const event = new CustomEvent(MessageService.NEW_MSG_EVENT, { detail: data });
  const messageElement = document
  .querySelector(`#${MessageService.MESSAGE_ELEMENT}`);
  if (messageElement) {
    messageElement.dispatchEvent(event);
  }
};

/**
 * This method will bubble up the announcement event
 * to eleemnt residing in DOM, all the listerners will be
 * getting the data.
 * @param {*} data 
 */
export const emitAnnouncementEvent = (data) => {
  const event = new CustomEvent(MessageService.NEW_ANNOUNCEMENT, { detail: data });
  const announcementElement = document.querySelector(`#${MessageService.ANNOUNCEMENT_ELEMENT}`);
  if (announcementElement) {
    announcementElement.dispatchEvent(event);
  }
};