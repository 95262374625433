import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './OmniSearch.style';
import { Box, ListItem, Paper, withStyles, Grow, Button } from '../MaterialUIComponents';
import { Typography, SchoolIcon } from '@orijinworks/frontend-commons';
import { useOmniSearchHook } from './useOmniSearchHook';

const OmniSearch = props => {
  const { classes, users, openProfile, showingSearchResults, totalSearchResults, searchInactive, toggleSearchStatus, history } = props;
  const { getIcon, getDescriptionElement } = useOmniSearchHook(props);

  const handleOpenProfile = (user, roleName, personId, locationId) => {
    const url = `/users/${personId}`;
    history.push(url);
    openProfile(user, roleName, personId, locationId);
  };

  return (
    <Paper
      classes={{
        root: [classes.root, classes.mainWrapper, users.length === 0 ? classes.notFoundWrapper : null].join(' ')
      }}
    >
      <Box className={[classes.scrollable, users.length === 0 ? classes.notFoundScrollable : ''].join(' ')}>
        {users.map((user, index) => (
          <Grow in timeout={index * 200} key={`user-grow-${user.personId}`}>
            <ListItem
              id={`user-${user.personId}`}
              button
              onClick={() => handleOpenProfile(user, user.roleName, user.personId, user.locationHistory[0].assignedLocationId)}
              classes={{
                root: classes.listItem
              }}
            >
              <Box className={classes.itemHead}>
                <Box className={classes.headIcon}>{getIcon(user.roleName)}</Box>
                <Box className={classes.headTextWrapper}>
                  <Typography variant="h6">{user.userName}</Typography>
                </Box>
              </Box>
              <Box className={classes.itemBody}>
                <Box className={classes.bodyTextWrapper}>{getDescriptionElement(user)}</Box>
              </Box>
            </ListItem>
          </Grow>
        ))}
        {users.length === 0 && (
          <Grow in timeout={1000}>
            <Typography id="no-user-found" variant="body1">
              No User(s) Found!
            </Typography>
          </Grow>
        )}
      </Box>
      <Box className={[classes.footer, classes.alignFooterButton]}>
        <Button
          onClick={toggleSearchStatus}
          variant={searchInactive ? 'contained' : 'outlined'}
          color="primary"
          className={searchInactive ? '' : classes.buttonPrimaryOutlined}
        >
          {searchInactive ? 'Show Active Learners' : 'Show Inactive Learners'}
        </Button>
        <Typography id="omni-search-footer" variant="body2" className={classes.footerText}>
          Showing {showingSearchResults}/{totalSearchResults} Results
        </Typography>
      </Box>
    </Paper>
  );
};

export default withRouter(withStyles(styles)(OmniSearch));
