import React from 'react';
import { Chip } from '@orijinworks/frontend-commons';
import { Box } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/styles';
import styles from './flagged-keywords.style';
import { withUnleash } from '../../../../v2/core/unleash';
import { UnleashService } from '../../../../v2/services';

const FlaggedKeywords = (props) => {
  const { classes, keywordsData, unleashProps } = props;

  return (
    unleashProps.isFlagEnabled() && (
      <Box className={classes.container}>
        <Typography id="keywords-section-label" variant="h3" className={classes.title}>
          Keywords Detected
        </Typography>
        {keywordsData?.length > 0 ? (
          <Box className={classes.keywordContainer}>
            {keywordsData.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                variant="outlined"
                size="small"
                classes={{
                  root: classes.rootChip,
                  outlined: classes.outlinedChip,
                }}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body1" id="no-keywords-label">
            No keywords detected
          </Typography>
        )}
      </Box>
    )
  );
};

export default withStyles(styles)(withUnleash(FlaggedKeywords, UnleashService.FLAGS.KEYWORD_MATCH_CONFIG));
