import React, { useCallback } from 'react';
import { LocationPickerService, UnleashService } from '../../../services';
import { getUnreadCountData, getUnreadCountFromApi } from '../../../../util/Helpers';
import { setLocation, setHousingUnit, SELECTED_LOCATION, USER_CONTEXT, SELECTED_HOUSING_UNIT, POLL_CONFIG } from '../../../../constants';
import { UserStorage } from '../../../../util/UserStorage';
import { EVENTS, HU_CACHE_KEY, LOCATION_CACHE_KEY, LOCATION_TYPE } from '../../../services/constants';
import useUnleashHook from '../../../core/unleash/useUnleashHook';
import { eventEmitter } from '../../../services/event-emitter-service';

export const useLocationPickerHook = ({
  selectLocation,
  selectHousingUnit,
  history,
  defaultExpandedState,
  showLocationPicker,
  unleashProps,
  setupCacheLocationHandler,
  unreadCountByLocations
}) => {
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [searchResults, setResult] = React.useState(null);
  const [collapseIn, setCollapseIn] = React.useState(false); //MATERIAL ANIMATION COLLAPSE
  const [selectedLocation, setSelectedLocation] = React.useState(SELECTED_LOCATION);
  const [isExpanded, setExpanded] = React.useState(defaultExpandedState); // MAIN SELECTOR EXPANDED STATE
  const [routeMetaTag, setRouteMetaTag] = React.useState(0);
  const [isAllSectionExpanded, setAllSectionExpanded] = React.useState(false); //SECTION COLLAPSE
  const locationPickerService = new LocationPickerService();
  const { isFlagEnabled } = useUnleashHook(UnleashService.FLAGS.HIDE_HOUSING_UNITS_VOICE_CALL_MODULE);
  const [hideHousingUnitOnVoiceCallModule, setHideHousingUnitOnVoiceCallModule] = React.useState(false);
  const ENUM = {
    FACILITY: 'facility',
    HOUSING_UNIT: 'housing-unit',
  };
  const userStorage = new UserStorage(USER_CONTEXT.personId);
  React.useEffect(() => {
    if (isFlagEnabled()) disableHousingUnitsWhenVoiceCallModule();
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (!showLocationPicker) {
      setExpanded(false);
      return;
    }
    setExpandedOnRouteChange();
  }, [routeMetaTag]);

  React.useEffect(() => {
    if (isExpanded && data.length === 0) {
      getData();
    }
  }, [isExpanded]);

  React.useEffect(() => {
    getData();
  }, [unreadCountByLocations]);
  
  React.useEffect(() => {
    setupCacheLocation();
    history.listen((location, action) => {
      setRouteMetaTag(Math.random());
    });
  }, []);

  const setupCacheLocation = () => {
    setupCacheLocationHandler(() => {
      if (unleashProps.isFlagEnabled()) {
        setSelectedLocation(getSelectedLocation());
      } else {
        setSelectedLocation(SELECTED_LOCATION);
      }
      setLoading(false);
    });
  };
  const getSelectedLocation = () => {
    if (SELECTED_HOUSING_UNIT && Object.keys(SELECTED_HOUSING_UNIT).length > 0) {
      return SELECTED_HOUSING_UNIT;
    } else {
      return SELECTED_LOCATION;
    }
  };

  const disableHousingUnitsWhenVoiceCallModule = () => {
    if (history.location.pathname.includes('/voiceCalls')) {
      setHideHousingUnitOnVoiceCallModule(true);
      handleVoiceCallModule();
    } else {
      setHideHousingUnitOnVoiceCallModule(false);
    }
  };

  const handleVoiceCallModule = () => {
    try {
      const locationType = userStorage.getItem(LOCATION_TYPE.KEY);
      if (locationType === LOCATION_TYPE.HOUSING_UNIT) {
        handleHousingUnitSelection();
      } else if (locationType === LOCATION_TYPE.FACILITY) {
        handleFacilitySelection();
      }
    }
    catch (e) {
      console.log('Error in handleVoiceCallModule', e);
      setSelectedLocation(null);
    }

  };

  const handleHousingUnitSelection = () => {
    const housingUnit = userStorage.getItem(HU_CACHE_KEY);
    const parentId = JSON.parse(housingUnit).parentLocationId;
    const facility = findFacilityByParentId(parentId);
    if (facility) {
      return setSelectedLocation(facility);
    }
    return setSelectedLocation(null);
  };

  const findFacilityByParentId = (parentId) => {
    for (const item of data) {
      const facility = item.children.find((child) => child.locationId == parentId);
      if (facility) {
        return facility;
      }
    }
    return null;
  };

  const handleFacilitySelection = () => {
    const location = userStorage.getItem(LOCATION_CACHE_KEY);
    setSelectedLocation(JSON.parse(location));
  };

  const setExpandedOnRouteChange = () => {
    // SETTING EXPANDED TO TRUE
    // IF ROUTE HAS BEEN CHANGED
    // AND LOCATION IS NOT SELECTED
    if (!isExpanded && !selectedLocation.locationId && showLocationPicker) {
      setExpanded(true);
    }
  };

  const getData = useCallback(async () => {
    const locationData = locationPickerService.getLocationData(setAllSectionExpanded);
    setCollapseIn(true);
    if (POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
      await getUnreadCountFromApi(locationData);
    } else {
      await getUnreadCountData(locationData, unreadCountByLocations);
    }
    setData(locationData);
    setLoading(false);
  }, [unreadCountByLocations]);

  const selectLocationCallback = (facility, locationType, housingUnit) => {
    setExpanded(false);
    userStorage.removeItem(LOCATION_CACHE_KEY);
    userStorage.removeItem(HU_CACHE_KEY);
    switch (locationType) {
      case ENUM.FACILITY:
        userStorage.setItem(LOCATION_CACHE_KEY, JSON.stringify(facility));

        //SELECTED LOCATION IN COMPONENT
        setSelectedLocation(facility);
        handleLocationChange(facility, null);
        break;
      case ENUM.HOUSING_UNIT:
        userStorage.setItem(LOCATION_CACHE_KEY, JSON.stringify(facility));
        userStorage.setItem(HU_CACHE_KEY, JSON.stringify(housingUnit));
        userStorage.setItem(LOCATION_TYPE.KEY, LOCATION_TYPE.HOUSING_UNIT);

        setSelectedLocation(housingUnit);
        handleLocationChange(facility, housingUnit);
        break;
    }
  };

  const handleLocationChange = (facility, housingUnit) => {
    // SETTING LOCATION IN REDUX
    selectLocation(facility);
    setLocation(facility);
    // SETTING HUs IN REDUX
    setHousingUnit(housingUnit);
    selectHousingUnit(housingUnit);
    eventEmitter.emit( EVENTS.LOCATION_CHANGE, {facility, housingUnit});
  };

  const toggleExpansion = (locationId, locationType, facilityId) => {
    const isSearchResult = searchResults ? true : false;
    locationPickerService.toggleSection(locationId, locationType, facilityId, data, searchResults, isSearchResult);
    if (isSearchResult) {
      setResult([...searchResults]);
      setAllSectionExpanded(locationPickerService.isAllCollapsed(searchResults));
    } else {
      setData([...data]);
      setAllSectionExpanded(locationPickerService.isAllCollapsed([...data]));
    }
  };

  const searchLocation = (searchText) => {
    const searchResultsTemp = locationPickerService.search(searchText, data);
    if (searchText !== '') {
      setResult(searchResultsTemp);
    } else {
      setResult(null);
    }
  };

  const collapseAll = (collapseType) => {
    const isSearchResult = searchResults ? true : false;
    const _data = locationPickerService.collapseAll(data, searchResults, isSearchResult, collapseType);

    if (isSearchResult) {
      setResult(_data);
    } else {
      setData(_data);
    }
    setAllSectionExpanded(collapseType);
  };

  const getIteratableData = () => {
    if (searchResults) {
      return searchResults;
    } else if (data.length > 0) {
      return data;
    }
    return [];
  };

  return {
    ENUM,
    data,
    isLoading,
    searchResults,
    collapseIn,
    selectedLocation,
    isExpanded,
    isAllSectionExpanded,
    selectLocationCallback,
    toggleExpansion,
    searchLocation,
    collapseAll,
    setExpanded,
    getIteratableData,
    hideHousingUnitOnVoiceCallModule,
    disableHousingUnitsWhenVoiceCallModule,
    handleFacilitySelection,
    findFacilityByParentId,
    handleHousingUnitSelection,
    handleVoiceCallModule,
    setData,
  };
};
