const styles = (theme) => ({
  container: {
    padding: '10px 0',
    justifyContent: 'left',
  },
  title: {
    marginBottom: theme.spacing(2),
  },

  keywordContainer: {
    width: '696px',
    height: '177px',
    top: '20px',
    left: '20px',
    padding: '12px 0px 0px 12px',
    gap: '8px',
    borderRadius: '4px 0px 0px 0px',
    border: '1px solid #E0E0E0',
    flexWrap: 'wrap',
    overflowY: 'auto', // Add vertical scroll
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'baseline',
  },
  rootChip: {
    '&.MuiChip-root': {
      textTransform: 'capitalize',
      border: '1px solid',
    },
  },
  outlinedChip: {
    '&.MuiChip-outlined': {
      color: theme.palette.colors.red.deep,
    },
  },
});

export default styles;
