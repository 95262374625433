import { Box, Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import React from 'reactn';
import Hoverable from '../../../hoverable';
import { VoiceCallService } from '../../services/voice-call.service';
import moment from 'moment-timezone';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { LOCATIONS } from '../../../../constants';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import { getSelectedLocationTimeZone } from '../../utils/utils';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
export const useCallHistoryListComponentHook = (props, isKeywordFlagEnabled) => {
  const defaultPageSize = 10;
  const voiceCallService = new VoiceCallService();
  const [callsData, setCallsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isDetailModalOpen, setDetailModalState] = useState(false);
  const [callDetails, setCallDetails] = useState({});
  const [recordingUrl, setRecordingUrl] = useState('');
  const [callStatus, setCallStatus] = useState('');
  const [transcriptData, setTranscriptData] = useState([]);
  const [detectedKeywords, setDetectedKeywords] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(3000);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(true);
  const tableRef = React.useRef();
  const [isPrivileged, setIsPrivileged] = useState(false);
  const [transcriptSummary, setTranscriptSummary] = useState({
    main_topics: '',
    inappropriate_violent_themes: [],
  });
  const [stateFilters, setStateFilters] = useState({
    learnerFirstName: '',
    learnerDocId: '',
    relationshipType: '',
    contactPhoneNumber: '',
    contactName: '',
    endTimeUtc: '',
    callState: [],
    voiceCallInsights: [],
  });
  const [allowedFilters, setAllowedFilters] = useState([
    'learnerFirstName',
    'learnerDocId',
    'relationshipType',
    'contactPhoneNumber',
    'contactName',
    'endTimeUtc',
    'callState',
    'voiceCallInsights',
  ]);
  const [queryForExport, setQueryForExport] = useState([]);
  useEffect(() => {
    if (props.isDataRefresh) {
      refreshTable();
      props.setDataRefresh(false);
    }
  }, [props.isDataRefresh]);

  useEffect(() => {
    refreshTable();
    props.setShouldExportCallHistory(false);
    props.setDataRefresh(false);
  }, [props.locationId]);

  useEffect(() => {
    if (props.shouldExportCallHistory && totalCount) {
      handleExportCsv(tableColumns);
      props.setShouldExportCallHistory(false);
    }
    if (props.shouldExportCallHistory && !totalCount) {
      handleSnackbar('No data to export', false, 2000);
    }
  }, [props.shouldExportCallHistory, totalCount]);

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  };
  const handleCloseHistoryDetailsModal = () => {
    setDetailModalState(false);
  };
  const onDetailsClick = async (callHistoryData) => {
    setRecordingUrl('');
    setTranscriptData([]);
    setTranscriptSummary({
      main_topics: '',
      inappropriate_violent_themes: [],
    });

    const { meetingId } = callHistoryData;
    setCallDetails(callHistoryData);
    setDetailModalState(true);
    const { preSignedURLAudioRecording, preSignedURLTranscript, privileged, keywords } =
      await voiceCallService.getVoiceCallDetails(meetingId);
    setDetectedKeywords(keywords);
    if (privileged) {
      setIsPrivileged(true);
    } else {
      setIsPrivileged(false);
      setRecordingUrl(preSignedURLAudioRecording);
      getTranscriptData(preSignedURLTranscript);
    }
  };
  const getTranscriptData = async (preSignedURLTranscript) => {
    const transcriptData = await voiceCallService.getVoiceCallTranscription(preSignedURLTranscript);
    if (transcriptData) {
      setTranscriptData(transcriptData);
      setTranscriptSummary((prevState) => ({
        ...prevState,
        main_topics: transcriptData.summary && transcriptData.summary.main_topics ? transcriptData.summary.main_topics : '',
        inappropriate_violent_themes:
          transcriptData.summary && transcriptData.summary.inappropriate_violent_themes
            ? transcriptData.summary.inappropriate_violent_themes
            : [],
      }));
    }
  };

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    if (!query) return filters;
    if (!query.filters) return filters;
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        if (Array.isArray(value)) value = [...item.value];
        filters[item.column.field] = value;
      }
    }
    return filters;
  };

  const createRequestPayload = (query) => {
    const selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    const tbody = {
      endTimeUtc: selectedFilters.endTimeUtc ? moment(selectedFilters.endTimeUtc).format('YYYY-MM-DD') : '',
      learnerFirstName: selectedFilters.learnerFirstName,
      learnerDocId: selectedFilters.learnerDocId,
      relationshipType: selectedFilters.relationshipType,
      contactPhoneNumber: selectedFilters.contactPhoneNumber,
      firstName: selectedFilters.contactName,
      lastName: selectedFilters.contactName,
      voiceCallInsights: selectedFilters.voiceCallInsights.join(),
    };
    let callStatus = Object.values(VOICE_CALL.CALL_HISTORY_STATUS).join().toLowerCase();
    if (selectedFilters.callState.length > 0) {
      callStatus = selectedFilters.callState.join();
    }
    return { tbody, callStatus };
  };

  const getCallHistoryListData = async (query) => {
    if (!props.locationId) return;
    if (!query) return;
    const { orderBy, orderDirection } = query;
    //setQueryForExport(...query);
    // setLoading(true);
    const { tbody, callStatus } = createRequestPayload(query);
    return new Promise((resolve, reject) => {
      voiceCallService
        .getLiveCallsList({
          locationId: props.locationId,
          status: callStatus,
          pageNumber: query.page,
          size: query.pageSize,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
          tbody,
        })
        .then((data) => {
          const { content, pageable, totalElements } = data;
          if (content) {
            resolve({
              data: content,
              page: (pageable && pageable.pageNumber) || 0,
              totalCount: totalElements,
            });
            setCallsData(content);
            setTotalCount(totalElements);
          } else reject(new Error('Something went wrong'));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    });
  };

  const exportCallHistoryListData = async () => {
    try {
      if (tableRef && tableRef.current && tableRef.current.state) {
        const { query } = tableRef.current.state;
        const { orderBy, orderDirection } = query;
        const { tbody, callStatus } = createRequestPayload(query);
        const payload = {
          locationId: props.locationId,
          status: callStatus,
          size: totalCount,
          pageNumber: 0,
          tbody,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
        };
        const { content } = await voiceCallService.getLiveCallsList(payload);
        if (content) {
          return content;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleExportCsv = async (tableColumns) => {
    const content = await exportCallHistoryListData();
    if (!content) {
      handleSnackbar('No data to export', false, 2000);
      return;
    }
    const csvContent = generateCsvContent(tableColumns, content);
    if (csvContent) {
      generateCsv(csvContent);
      handleSnackbar('CSV file has been exported successfully', true, 2000);
    }
  };

  const generateCsvContent = (tableColumns, content) => {
    const exemptedColumnsList = ['Actions'];
    const columnTitles = tableColumns
      .filter((column) => !exemptedColumnsList.includes(column.title))
      .map((column) => column.title)
      .join(',');

    let csvContent = columnTitles + '\r\n';
    const domParser = new DOMParser();

    content.forEach((item) => {
      const rowString = tableColumns
        .map((column) => {
          if (column.field) {
            let value = getColumnValue(item, column);
            const document = domParser.parseFromString(value, 'text/html');
            return '"' + document.body.innerText + '"';
          }
        })
        .join(',');
      csvContent += rowString + '\r\n';
    });

    return csvContent;
  };

  const getColumnValue = (item, column) => {
    let contactInfo = item.contactInfo ? JSON.parse(item.contactInfo) : null;
    switch (column.title) {
      case 'Resident Name':
        return `${item.learnerFirstName || ''} ${item.learnerLastName || ''}`.trim();
      case 'Phone Number':
        return item.contactPhoneNumber || '';
      case 'Contact Name':
        return contactInfo ? `${contactInfo.firstName} ${contactInfo.lastName}` : '';
      case 'Relationship':
        return contactInfo ? contactInfo.relationshipType : '';
      case 'Call End Time':
        return item.endTimeUtc || '';
      default:
        return item[column.field] || '';
    }
  };

  const generateCsv = (csvContent) => {
    let locationName = getLocationName(props.locationId);
    const exportedFilename = `${locationName} Calls History.csv`;
    const universalBOM = '\uFEFF';
    const downloadLink = document.createElement('a');
    downloadLink.download = exportedFilename;
    downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
    downloadLink.click();
  };

  /**
   * @name getLocationName
   * @param {number} locationId
   * @desc Based upon locationId it finds in LOCATIONS collection
   * for matching location and shows locationName instead.
   * @return {string}
   */
  const getLocationName = (locationId) => {
    const location = LOCATIONS.find((location) => location.locationId === locationId);
    if (location) {
      return location.locationName;
    } else {
      return '';
    }
  };

  let tableColumns = [
    {
      title: 'Resident Name',
      field: 'learnerFirstName',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => (
        <Hoverable title={rowData.learnerFirstName}>{rowData.learnerFirstName + ' ' + rowData.learnerLastName}</Hoverable>
      ),
    },
    {
      title: 'DOC ID',
      field: 'learnerDocId',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => <Hoverable title={rowData.learnerDocId}>{rowData.learnerDocId}</Hoverable>,
    },
    {
      title: 'Contact Name',
      field: 'contactName',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { firstName, lastName } = JSON.parse(rowData.contactInfo);
        const fullName = lastName ? `${firstName} ${lastName}` : firstName;
        return <Hoverable title={fullName}>{fullName}</Hoverable>;
      },
    },
    {
      title: 'Phone Number',
      field: 'contactPhoneNumber',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        return <Hoverable title={rowData.contactPhoneNumber}>{rowData.contactPhoneNumber}</Hoverable>;
      },
    },
    {
      title: 'Relationship',
      field: 'relationshipType',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { relationshipType } = JSON.parse(rowData.contactInfo);
        return <Hoverable title={relationshipType}>{relationshipType}</Hoverable>;
      },
    },
    {
      title: 'Call End Time',
      field: 'endTimeUtc',
      defaultSort: 'desc',
      type: 'date',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const endTimeUtc = rowData.endTimeUtc;

        if (endTimeUtc === null) {
          return '';
        }
        const timeZone = getSelectedLocationTimeZone();
        const formattedDate = moment.utc(rowData.endTimeUtc).tz(timeZone).format('MM-DD-YYYY hh:mm:ss A');
        return <Hoverable title={formattedDate}>{formattedDate}</Hoverable>;
      },
    },
    {
      title: 'Call Status',
      field: 'callState',
      filtering: true,
      sorting: false,
      lookup: VOICE_CALL.CALL_HISTORY_STATUS,
      render: (rowData) => (
        <Typography className={getCallStatusClassName(rowData, props.classes)}>
          {' '}
          {capitalizeFirstLetter(rowData.callState)}
        </Typography>
      ),
    },
    ...(isKeywordFlagEnabled
      ? [
          {
            title: 'Call Insights',
            field: 'voiceCallInsights',
            filtering: true,
            lookup: Object.fromEntries(
              Object.values(VOICE_CALL.VOICE_INSIGHTS)
                .filter(({ value }) => value !== VOICE_CALL.VOICE_INSIGHTS.VOICE_MATCH_RESIDENT.value)
                .map(({ value, label }) => [value, label])
            ),
            sorting: false,
            render: (rowData) => {
              const { voiceCallInsights } = rowData;

              if (!voiceCallInsights || voiceCallInsights.length === 0) {
                return null; // No insights to display
              }

              // Collect unique events using a Set for event types
              const uniqueInsights = [];
              const seenEventTypes = new Set();

              for (const event of voiceCallInsights) {
                if (!seenEventTypes.has(event.eventType)) {
                  uniqueInsights.push(event);
                  seenEventTypes.add(event.eventType);
                }
              }

              return (
                <>
                  {uniqueInsights
                    .filter(
                      (event) =>
                        VOICE_CALL.VOICE_INSIGHTS[event.eventType] &&
                        VOICE_CALL.VOICE_INSIGHTS[event.eventType].value !==
                          VOICE_CALL.VOICE_INSIGHTS.VOICE_MATCH_RESIDENT.value
                    )
                    .map((event, index) => (
                      <Box key={index}>
                        <VoiceInsightChip label={VOICE_CALL.VOICE_INSIGHTS[event.eventType]?.label} />
                      </Box>
                    ))}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: 'Actions',
      sorting: false,
      filtering: false,
      render: (rowData) => {
        if (
          [VOICE_CALL.CALL_HISTORY_STATUS.COMPLETED, VOICE_CALL.CALL_HISTORY_STATUS.TERMINATED].includes(rowData.callState)
        ) {
          return (
            <Button onClick={() => onDetailsClick(rowData)} style={{ fontWeight: 'bold' }}>
              Details
            </Button>
          );
        }
      },
    },
  ];

  const parseJoinDateIfValid = (endTimeUtc) => {
    if (endTimeUtc) {
      return moment(endTimeUtc).format('MM-DD-YYYY hh:mm:ss A');
    }
    return '';
  };
  const getCallStatusClassName = (rowData, classes) => {
    switch (rowData.callState) {
      case 'COMPLETED':
        return classes.success;
      case 'MISSED':
        return classes.warning;
      case 'TERMINATED':
        return classes.danger;
      default:
        return '';
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
  };

  const handleSnackbar = (message, success, duration) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(duration);
    setIsSuccessSnackbar(success);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return {
    tableColumns,
    callsData,
    isLoading,
    defaultPageSize,
    totalCount,
    isDetailModalOpen,
    callDetails,
    recordingUrl,
    callStatus,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
    handleCloseHistoryDetailsModal,
    getCallStatusClassName,
    openSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    handleCloseSnackbar,
    getCallHistoryListData,
    exportCallHistoryListData,
    tableRef,
    handleExportCsv,
    generateCsvContent,
    generateCsv,
    parseJoinDateIfValid,
    capitalizeFirstLetter,
    getLocationName,
    getColumnValue,
    isPrivileged,
  };
};
