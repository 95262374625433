import React from 'react';
import { Dialog, Divider, Box, IconButton, AppBar, Toolbar, Typography, Button, DialogContent, Grid } from '@material-ui/core';
import { USER_CARD_DATETIME_FORMAT } from '../../../constants';

const readView = (props) => {
    return props.users
        .map(
            (user) => (
                <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <div style={{ width: '100%' }}>
                            <h2 style={{fontWeight: 500}}>{`Learner Login Information`}</h2>
                        </div>
                        {user.userDetail && (
                            <div>
                                <h3 style={{fontWeight: "normal"}}>{`${user.userDetail.firstName} ${user.userDetail.lastName}`}</h3>
                            </div>
                        )}
                        {user.userLocations.length > 0 && (
                            <div style={{ width: '100%' }}>
                                <h3 style={{fontWeight: "normal"}}>Current Location: {user.userLocations[0].locationTypeId === 15 ? `${user.userLocations[0].parentLocationName}, ${user.userLocations[0].locationName}` : `${user.userLocations[0].locationName}`}</h3>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12}>
                        {user.userDetail && (
                        <>
                            <div style={{ margin: '1rem' }}>
                                <h5 style={{ marginBottom: '.2rem' }}>Connected Corrections</h5>
                                <div style={{ padding: '.5rem' }}>
                                    <p style={{ margin: '.2rem' }}>Username: {user.userDetail.userName || "n/a"}</p>
                                    <p style={{ margin: '.2rem' }}>Password: {user.userDetail.password || "n/a"}</p>
                                </div>
                            </div>
                            <div>
                                <h5 style={{ margin: '1rem' }}>
                                    The following educational resources require a login and password. After logging into Connected Corrections click on the corresponding tile/button and you will be prompted to enter a username and password.
                                </h5>
                            </div>
                        </>
                        )}

                        {(user.userServices || [])
                        .filter( service => service.active === "true" )
                        .map((s, i) =>
                            <>
                                {!s.ssoEnabled && (
                                    <div key={i}>
                                        {(s.serviceKey != (124) && s.serviceKey != (125)) ?
                                            <div style={{ margin: '1rem' }}>
                                                <h5 style={{ marginBottom: '.2rem' }}>{s.serviceDisplayName}</h5>
                                                <div style={{ padding: '.5rem' }}>
                                                    <p style={{ margin: '.2rem' }}>Username: {s.externalUserName || "n/a"}</p>
                                                    <p style={{ margin: '.2rem' }}>Password: {s.externalUserPassword || "n/a"}</p>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                )}

                            </>
                        )}
                    </Grid>
                    <Divider />
                </>
            )
        );
};


export default readView;