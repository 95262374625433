import { useState, useEffect } from 'react';
import { uploadCTAData } from '../../services/manage-ads.service';
import isURL from 'validator/lib/isURL';

const useCallToActionHook = (ads, isAdEnabled, toggleError) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [showCTALoader, setShowCTALoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');
  const [buttonLabelError, setButtonLabelError] = useState('');
  const [buttonUrlError, setButtonUrlError] = useState('');
  const [adId, setAdId] = useState('');
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showCancel, setshowCancel] = useState(false);

  const URL_OPTIONS = {
    require_protocol: true,
    require_valid_protocol: true,
    protocols: ['http', 'https'],
  };

  const updateAdData = (ads) => {
    if (!ads?.length) return;

    const { ctaData, id } = ads[0];
    setButtonLabel(ctaData?.actionButtonText || 'Button Label');
    setButtonUrl(ctaData?.link || 'http://learner.connectedcorrections.com/');
    setIsEnabled(ctaData?.enabled ?? isEnabled);
    setAdId(id);
  };

  useEffect(() => {
    updateAdData(ads);
  }, [ads]);

  useEffect(() => {
    if (uploadSuccess) {
      const timer = setTimeout(() => {
        setUploadSuccess(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [uploadSuccess]);

  const handleEditClick = () => {
    setIsEditing(true);
    setshowCancel(true);
  };

  const handleCancelClick = () => {
    updateAdData(ads);
    setIsEditing(false);
  };

  const handleCTAVisibility = async (event) => {
    try {
      const status = event.target.checked;
      setShowCTALoader(true);
      await uploadCTAData(adId, status, buttonLabel, buttonUrl, Boolean(isAdEnabled));
      setIsEnabled(status);
    } catch (error) {
      console.error(error);
      toggleError('Error toggling Call to Action visibility. Please try again later.');
    } finally {
      setShowCTALoader(false);
    }
  };

  const handleUploadCTAData = async () => {
    setshowCancel(false);
    if (!buttonLabel) {
      setButtonLabelError('Label is required');
      return;
    }
    if (!buttonUrl) {
      setButtonUrlError('URL is required');
      return;
    }
    if (buttonLabel.length > 25) {
      setButtonLabelError('Max 25 characters');
      return;
    }
    if (buttonUrl && !isURL(buttonUrl, URL_OPTIONS)) {
      setButtonUrlError('Invalid URL format');
      return;
    }
    setShowSaveLoader(true);
    setUploadSuccess(false);
    try {
      const response = await uploadCTAData(adId, isEnabled, buttonLabel, buttonUrl, Boolean(isAdEnabled));
      if (response.status === 'Success') {
        setIsEditing(false);
        setButtonLabelError('');
        setButtonUrlError('');
        setUploadSuccess(true);
      }
    } catch (error) {
      console.error(error);
      toggleError('Error uploading data. Please try again later.');
    } finally {
      setShowSaveLoader(false);
    }
  };

  const handleButtonLabelChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setButtonLabelError('Label is required');
    } else if (value.length > 25) {
      setButtonLabelError('Max 25 characters');
    } else {
      setButtonLabelError('');
    }
    setButtonLabel(value);
  };

  const handleButtonUrlChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setButtonUrlError('URL is required');
    } else if (value && !isURL(value, URL_OPTIONS)) {
      setButtonUrlError('Invalid URL format');
    } else {
      setButtonUrlError('');
    }
    setButtonUrl(value);
  };

  return {
    isEnabled,
    isEditing,
    buttonLabel,
    buttonUrl,
    buttonLabelError,
    buttonUrlError,
    showCTALoader,
    showSaveLoader,
    uploadSuccess,
    showCancel,
    setButtonLabel,
    setButtonUrl,
    handleEditClick,
    handleUploadCTAData,
    handleCancelClick,
    handleButtonLabelChange,
    handleButtonUrlChange,
    handleCTAVisibility,
  };
};
export default useCallToActionHook;
