import {CustomTheme} from "@orijinworks/frontend-commons"

export default (theme) => ({
    sendEditorWrapper: {
        border: "1px solid #DFE6E9",
        margin: 20,
        display: "flex",
        borderRadius: 5
    },
    sendEditorWrapperDisabled: {
        border: "none",
        borderTop: "1px solid #DFE6E9",
        justifyContent: "flex-end",
        paddingTop: "20px"
    },
    noRelationshipMessage: {
        color: '#be311f',
        margin: "20px",
        display: "flex",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
    },
    sendEditor: {
        border: "none",
        flex: "1",
        outline: "none",
        padding: 20,
        fontSize: "16px",
        borderRadius: 5,
        minHeight: "110px",
        fontFamily: CustomTheme.typography.fontFamily
    },
    sendBtnWrapper: {
        display: "flex",
        alignItems: "center"
    },
    sendBtn: {
        padding: "10px 20px",
        backgroundColor: theme.palette.primary.mainBlue,
        color: "white",
        marginRight: 20,
        minWidth: 110,
        minHeight: 45,
        "&[disabled], &:hover": {
            backgroundColor: theme.palette.primary.mainBlue,
            color: "white",
            opacity: 0.8
        }
    },
    sendIcon: {
        marginRight: 10
    },
    characterLimitWrapper: {
        color: "#707070",
        textAlign: "right",
        margin: "0px 20px 20px",
        fontSize: "14px"
    },
});