const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.colors.redMain}`,
    borderRadius: '4px',
    padding: '6px',
    backgroundColor: '#FFECF0', //todo to be discussed
    fontSize: '16px',
    fontWeight: '700',
    color: theme.palette.colors.red.deep,
    height: '24px',
    marginBottom: '8px',
    width: '300px !important',
  },
});

export default styles;
