import { POLL_CONFIG, SOCKETIO_RECONNECT_INTERVAL } from '../constants';
import { emitAnnouncementEvent } from '../util/Helpers';
import { getAccessToken } from './core/auth/utils';

const URL = process.env.REACT_APP_MERCURY_URL;

const { io } = require('socket.io-client');

let roomId = '';
let joined = false;
let socket;
let tokenInterval = null;

const registerdFunction = {
  new_staff_message: null,
  relationship: null,
};

export function establishSocketConnection() {
  tokenInterval = setInterval(async () => {
    let token = await getAccessToken();
    if (token) {
      clearInterval(tokenInterval);
    }

    socket = io(URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    socket.on('connect', function () {
      console.log(`Socket ${socket.id} CONNECTED`);
      joinRoom(socket.id);
    });

    socket.on('disconnect', function (reason) {
      console.log(`Socket ${socket.id} DISCONNECTED. Reason: ${reason}`);
      joined = false;
    });

    socket.on('connect_error', () => {
      setTimeout(() => {
        socket.connect();
      }, SOCKETIO_RECONNECT_INTERVAL);
    });

    socket.on('message', function (data) {
      if (registerdFunction[data.action]) {
        registerdFunction[data.action](data.payload || data.data);
      } else if (registerdFunction[`${data.action}_for_unread_count`]) {
        registerdFunction[`${data.action}_for_unread_count`](data.payload);
      }
    });

    socket.on('announcement', function (data) {
      emitAnnouncementEvent(data);
    });

    socket.on('voice_keyword_detected', function (data) {
      try {
        if (registerdFunction[data.action]) {
          registerdFunction[data.action](data.payload);
        }
      } catch (error) {
        console.error('Error handling voice_keyword_detected event:', error);
      }
    });
  }, 5000);
}

export function registerAction(callback) {
  registerdFunction[callback.event] = callback.action;
}

export function removeAction(event) {
  registerdFunction[event] = false;
}

export function joinRoom(id) {
  if (!POLL_CONFIG.USE_POLLING) {
    roomId = id;
    if (joined) {
      return;
    }
    if (socket) {
      console.log(`roomId :${roomId}  Socket ${socket.id} CONNECTED`);
      socket.emit('message', {
        action: 'join_room',
        room_id: roomId,
      });
      joined = true;
    }
  }
}

export function joinMeetingRoom(meetingId) {

  if(!meetingId){
    return;
  }

  if(!socket || !socket.id){
    console.log('Socket not connected. Establishing connection');
    establishSocketConnection();
  }

  console.log(`meetingId :${meetingId}  Socket ${socket.id} CONNECTED`);
  socket.emit('message', {
    action: 'join_room',
    room_id: meetingId,
  });
  
}