import React from 'react';
import { Dialog, Divider, Box, IconButton, AppBar, Toolbar, Typography, Button, DialogContent, TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { USER_CARD_DATETIME_FORMAT } from '../../../constants';

/**
 * @name handleServiceChange
 * @param {number} userIndex
 * @param {number} serviceIndex
 * @param {string} value
 * @param {string} fieldKey
 * @param {Function} setFieldValue
 * @param {Function} setFieldTouched
 * @param {array} initialValues
 * @desc Field change handler w.r.t user & service.
 * @return {void}
 */
const handleServiceChange = (userId, serviceId, value, fieldKey, setFieldValue, setFieldTouched, initialValues, fieldChangeDetector) => {
    const initialServiceValuesRef = initialValues
    .find(user => parseInt(user.userDetail.userId) === userId)
    .userServices // ITERATING SERVICE TO FIND VIA ID
    .find(service => service.serviceKey === serviceId);
    fieldChangeDetector(userId, initialServiceValuesRef.serviceKey, fieldKey, value);
    initialServiceValuesRef[fieldKey] = value;
    setFieldValue("users", initialValues);
    setFieldTouched("users", true);
}

const editView = (props) => {
    return props.users
        .map(
            (user, userIndex) => (
                <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <div style={{ width: '100%' }}>
                            <h2 style={{fontWeight: 500}}>{`Learner Login Information`}</h2>
                        </div>
                        {user.userDetail && (
                            <div>
                                <h3 style={{fontWeight: "normal"}}>{`${user.userDetail.firstName} ${user.userDetail.lastName}`}</h3>
                            </div>
                        )}
                        {user.userLocations.length > 0 && (
                            <div style={{ width: '100%' }}>
                                <h3 style={{fontWeight: "normal"}}>Current Location: {user.userLocations[0].locationTypeId === 15 ? `${user.userLocations[0].parentLocationName}, ${user.userLocations[0].locationName}` : `${user.userLocations[0].locationName}`}</h3>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12}>
                        {user.userDetail && (
                            <>
                                <div style={{ margin: '1rem' }}>
                                    <h5 style={{ marginBottom: '.2rem' }}>Connected Corrections</h5>
                                    <div style={{ padding: '.5rem' }}>
                                        <p style={{ margin: '.2rem' }}>Username: {user.userDetail.userName || "n/a"}</p>
                                        <p style={{ margin: '.2rem' }}>Password: {user.userDetail.password || "n/a"}</p>
                                    </div>
                                </div>
                                <div>
                                    <h5 style={{ margin: '1rem' }}>
                                        The following educational resources require a login and password. After logging into Connected Corrections click on the corresponding tile/button and you will be prompted to enter a username and password.
                                    </h5>
                                </div>
                            </>
                        )}
                        <div className="service-container">
                            {(user.userServices || [])
                        .filter( service => service.active === "true" )
                            .map((s, i) =>
                                <div key={i} >
                                    {(s.serviceKey != (124) && s.serviceKey != (125)) ?
                                        <div style={{ margin: '1rem' }}>
                                            <h5 style={{ marginBottom: '.2rem' }}>{s.serviceName}</h5>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                width="100%"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                >
                                                    <Grid item md={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={s.active === "true"}
                                                                    onChange={e => handleServiceChange(
                                                                        parseInt(user.userDetail.userId),
                                                                        s.serviceKey,
                                                                        e.target.checked.toString(),
                                                                        "active",
                                                                        props.setFieldValue,
                                                                        props.setFieldTouched,
                                                                        props.users,
                                                                        props.fieldChangeDetector
                                                                    )}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={s.active === "true" ? "Active" : "Inactive"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {!s.ssoEnabled && (
                                                <Box display="flex" flexDirection="column">
                                                    <Box
                                                        display="flex"
                                                    >
                                                        <TextField
                                                            error={
                                                                props.errors.users &&
                                                                props.errors.users[userIndex] &&
                                                                props.errors.users[userIndex].userServices[i] &&
                                                                props.errors.users[userIndex].userServices[i].externalUserName &&
                                                                props.touched.users}
                                                            helperText={
                                                                props.errors.users &&
                                                                    props.errors.users[userIndex] &&
                                                                    props.errors.users[userIndex].userServices[i] &&
                                                                    props.errors.users[userIndex].userServices[i].externalUserName &&
                                                                    props.touched.users
                                                                    ? props.errors.users[userIndex].userServices[i].externalUserName
                                                                    : null
                                                            }
                                                            onChange={e => handleServiceChange(
                                                                parseInt(user.userDetail.userId),
                                                                s.serviceKey,
                                                                e.target.value,
                                                                "externalUserName",
                                                                props.setFieldValue,
                                                                props.setFieldTouched,
                                                                props.users,
                                                                props.fieldChangeDetector
                                                            )}
                                                            style={{
                                                                width: "95%",
                                                                margin: 10
                                                            }}
                                                            value={s.externalUserName}
                                                            label="Username"
                                                        />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                    >
                                                        <TextField
                                                            error={
                                                                props.errors.users &&
                                                                props.errors.users[userIndex] &&
                                                                props.errors.users[userIndex].userServices[i] &&
                                                                props.errors.users[userIndex].userServices[i].externalUserPassword &&
                                                                props.touched.users}
                                                            helperText={
                                                                props.errors.users &&
                                                                    props.errors.users[userIndex] &&
                                                                    props.errors.users[userIndex].userServices[i] &&
                                                                    props.errors.users[userIndex].userServices[i].externalUserPassword &&
                                                                    props.touched.users
                                                                    ? props.errors.users[userIndex].userServices[i].externalUserPassword
                                                                    : null
                                                            }
                                                            onChange={e => handleServiceChange(
                                                                parseInt(user.userDetail.userId),
                                                                s.serviceKey,
                                                                e.target.value,
                                                                "externalUserPassword",
                                                                props.setFieldValue,
                                                                props.setFieldTouched,
                                                                props.users,
                                                                props.fieldChangeDetector
                                                            )}
                                                            style={{
                                                                width: "95%",
                                                                margin: 10
                                                            }}
                                                            type="password"
                                                            value={s.externalUserPassword}
                                                            label="Password"
                                                        />
                                                    </Box>
                                                </Box>
                                            )}

                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>

                            )}
                            <Divider />
                        </div>
                    </Grid>
                </>
            )
        );
};


export default editView;