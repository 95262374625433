import React from 'react';
import {
  Typography,
  Box,
  Collapse,
  withStyles,
  Button,
  Fade,
  Grid,
  CircularProgress,
  Tooltip
} from '../MaterialUIComponents';

import styles from './location-picker.style';
import { CustomIcons, icons } from '../custom-icons';
import { setLocation as selectLocation, setHousingUnit as selectHousingUnit } from '../../../core/store';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocationPickerHook } from './useLocationPickerHook';
import { Typography as CustomTypography, TextField } from '@orijinworks/frontend-commons';
import { withUnleash } from '../../../core/unleash';
import { UnleashService } from '../../../services';

export const LocationSection = ({ name, hasUnreadCount, handleClick, children, classes }) => {
  return (
    <>
      <div className={classes.contactNameStyle}>
        <Typography id={`location-name-${name}`} onClick={handleClick} className={[classes.locationName]}>
          {name}
        </Typography>
        {Boolean(hasUnreadCount) && <CustomIcons icon={icons.FiberManualRecordIcon} className={classes.unreadIcon} />}
        {children}
      </div>
    </>
  );
};
const LocationPicker = props => {
  const { classes, showLocationPicker } = props;
  const {
    ENUM,
    data,
    isLoading,
    searchResults,
    collapseIn,
    selectedLocation,
    isExpanded,
    isAllSectionExpanded,
    selectLocationCallback,
    toggleExpansion,
    searchLocation,
    collapseAll,
    setExpanded,
    getIteratableData,
    hideHousingUnitOnVoiceCallModule,
  } = useLocationPickerHook(props);

  return (
    <>
      <Fade in={isExpanded}>
        <Box id="backdrop" onClick={() => setExpanded(!isExpanded)} component={'div'} className={classes.backdrop} />
      </Fade>
      <Box className={classes.main}>
        <Box id="heading" className={classes.heading}>
          Location
        </Box>
        <Box className={[isExpanded ? classes.unsetBackgroundColor : null, classes.contentMain].join(' ')}>
          <Box
            id="clickable-location"
            className={[isExpanded ? classes.activeSelectedLocation : null, classes.selectedLocation].join(' ')}
            onClick={() => setExpanded(!isExpanded)}
          >
            <CustomTypography
              variant="body1"
              className={[selectedLocation.locationName ? classes.colorGrey110 : classes.colorGrey90, classes.mainText].join(
                ' '
              )}
              id="selected-location-name"
            >
              <CustomIcons icon={icons.Location} className={classes.locationIcon} />
              {showLocationPicker
                ? selectedLocation.locationName
                  ? selectedLocation.locationName
                  : 'Select a location'
                : ''}
            </CustomTypography>
            <Box className={classes.caret}>
              <CustomIcons
                icon={icons.ExpandLess}
                className={[isExpanded ? null : classes.accordionOpen, classes.collapseIcon].join(' ')}
              />
            </Box>
          </Box>
          {isLoading && isExpanded && (
            <Box className={classes.loaderBox} id="loading-wrapper">
              <CircularProgress />
            </Box>
          )}
          <Fade in={isExpanded && !isLoading}>
            <Box className={classes.expandableBox} id="expandable-box">
              <Box className={[classes.section, classes.borderTopNone].join(' ')}>
                <Box className={classes.searchWrapper}>
                  <CustomIcons icon={icons.FilterAltIcon} className={classes.searchIcon} id="searchIcon" />
                  <TextField
                    id="search-input"
                    type="text"
                    onKeyUp={e => searchLocation(e.target.value)}
                    className={classes.searchInput}
                    placeholder="Search"
                  />
                </Box>
              </Box>
              <Box className={classes.overflowBox}>
                {getIteratableData().map(
                  (_location, index) =>
                    _location.children &&
                    _location.children.length > 0 && (
                      <Collapse key={`${_location.locationId}-${index}`} in={collapseIn}>
                        <Box className={[classes.section, classes.jurisdiction].join(' ')}>
                          <Typography
                            id={`location-id-${_location.locationId}`}
                            className={classes.locationName}
                            onClick={() => toggleExpansion(_location.locationId, 'jurisdiction')}
                          >
                            {_location.locationName}
                            {_location.children && _location.children.length > 0 && (
                              <CustomIcons
                                icon={icons.ExpandLess}
                                className={[_location.isExpanded ? null : classes.accordionOpen, classes.collapseIcon].join(
                                  ' '
                                )}
                              />
                            )}
                          </Typography>
                          {(_location.children || []).map(_facility => (
                            <>
                            <Tooltip placement="top-right" interactive title={hideHousingUnitOnVoiceCallModule && _facility.children && _facility.children.length> 0 ? 'Only Facilities may be selected within Voice Calls' : ''}> 
                            <Collapse key={_facility.locationId} in={_location.isExpanded}>
                              <Box className={'sub-section'}>
                                <LocationSection
                                  name={_facility.locationName}
                                  locationId={_facility.locationId}
                                  hasUnreadCount={_facility.totalUnreadCount}
                                  handleClick={e => {
                                    if (!e.isDefaultPrevented()) {
                                      selectLocationCallback(_facility, ENUM.FACILITY);
                                    }
                                  }}
                                  classes={classes}
                                >
                                  {_facility.children && _facility.children.length > 0 &&
                                    <>
                                        <CustomIcons
                                          id={`facility-${_facility.locationId}`}
                                          disabled={hideHousingUnitOnVoiceCallModule}
                                          onClick={e => {
                                            e.preventDefault();
                                            toggleExpansion(_location.locationId, 'facility', _facility.locationId);
                                          }}
                                          icon={icons.ExpandLess}
                                          className={[
                                            _facility.isExpanded ? null : classes.accordionOpen,
                                            classes.collapseIcon,
                                            hideHousingUnitOnVoiceCallModule ? classes.disabledIcon : null
                                          ].join(' ')}
                                        />
                                    

                                    </>

                                  }
                                </LocationSection>
                                {!hideHousingUnitOnVoiceCallModule && (
                                  <Box className={'sub-section'}>
                                    {(_facility.children || []).map(_hu => (
                                      <>
                                        <Collapse key={_hu.locationName} in={_facility.isExpanded}>
                                          <LocationSection
                                            name={_hu.locationName}
                                            locationId={_hu.locationId}
                                            hasUnreadCount={_hu.totalUnreadCount}
                                            handleClick={() => {
                                              selectLocationCallback(_facility, ENUM.HOUSING_UNIT, _hu);
                                            }}
                                            classes={classes}
                                          />
                                        </Collapse>

                                      </>
                                    ))}

                                  </Box>
                                )}
                              </Box>
                            </Collapse>
                            </Tooltip>
                            
                            </>
                            

                          ))}
                        </Box>
                      </Collapse>
                    )
                )}

                {getIteratableData().length === 0 && (
                  <>
                    <Typography id="no-result" align="center" className={classes.mainText}>
                      No Result Found.
                    </Typography>
                    <br />
                  </>
                )}
              </Box>
              <Box className={[classes.section, classes.collapseAllWrapper].join(' ')}>
                <Button
                  id="collapse-all-button"
                  onClick={() => collapseAll(!isAllSectionExpanded)}
                  className={classes.collapseAllButton}
                  variant="contained"
                  disableElevation
                >
                  {isAllSectionExpanded ? 'Collapse All' : 'Expand All'}
                </Button>
              </Box>
            </Box>
          </Fade>
        </Box>
      </Box>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: location => dispatch(selectLocation(location)),
    selectHousingUnit: hu => dispatch(selectHousingUnit(hu))
  };
};

const mapStateToProps = (state) => {
  return state.rsMessageModuleStore;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withUnleash(LocationPicker, UnleashService.FLAGS.HU_DATA_CACHE_FIX))));
