const styles = (theme) => ({
  rootChip: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.secondary.red.main}`,
      textTransform: 'capitalize',
      maxWidth: 'fit-content',
    },
  },
  chipOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.colors.red.deep,
      backgroundColor: '#FFECF0',
    },
  },
});

export default styles;
