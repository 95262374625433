export const MESSAGES_TYPE = {
  SET_MESSAGES: 'SET_MESSAGES',
  SET_DIRECT_MESSAGES: 'SET_DIRECT_MESSAGES',
  SET_ANNOUNCEMENTS: 'SET_ANNOUNCEMENTS',
  SET_SELECTED_CONTACT: 'SET_SELECTED_CONTACT',
  SET_ACTIVE_THREAD: 'SET_ACTIVE_THREAD',
  SET_MSG_ANNOUNCEMENT_COUNT: 'SET_MSG_ANNOUNCEMENT_COUNT',
  SET_MESSAGE_TYPE: 'SET_MESSAGE_TYPE',
  SET_CHARACTER_LIMIT: 'SET_CHARACTER_LIMIT',
  SET_NEW_MESSAGE: 'SET_NEW_MESSAGE',
  SET_UNREAD_COUNT: 'SET_UNREAD_COUNT'
};

export const rsSetDirectMessages = (payload = { rsMessageThread: [] }) => {
  return { type: MESSAGES_TYPE.SET_DIRECT_MESSAGES, payload: payload };
};

export const rsSetAnnouncements = (payload = { rsAnnouncementsThread: [] }) => {
  return { type: MESSAGES_TYPE.SET_ANNOUNCEMENTS, payload: payload };
};

export const rsSetSelectedContent = (payload = { rsSelectedContact: {}, rsActiveThread: '' }) => {
  return { type: MESSAGES_TYPE.SET_SELECTED_CONTACT, payload: payload };
};

export const rsSetActiveThread = (payload = { rsActiveThread: '' }) => {
  return { type: MESSAGES_TYPE.SET_ACTIVE_THREAD, payload : payload };
};

export const rsSetUnReadMsgAnnouCount = (payload = { rsUnreadMsgAnnoCountObj: {} }) => {
  return { type: MESSAGES_TYPE.SET_MSG_ANNOUNCEMENT_COUNT, payload: payload };
};

export const setMessageType = payload => {
  return { type: MESSAGES_TYPE.SET_MESSAGE_TYPE, payload };
};

export const setCharacterLimit = payload => {
  return { type: MESSAGES_TYPE.SET_CHARACTER_LIMIT, payload };
};

export const setNewMessage = payload => {
  return { type: MESSAGES_TYPE.SET_NEW_MESSAGE, payload };
};

export const setUnreadCountByLocation = payload => {
  return { type: MESSAGES_TYPE.SET_UNREAD_COUNT, payload };
}
