import { Box, Button } from '@material-ui/core';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import { useEffect, useState } from 'react';
import React from 'reactn';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import Hoverable from '../../../hoverable';
import { VoiceCallService } from '../../services/voice-call.service';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
import {
  disconnectVoiceSocket,
  establishVoiceSocketConnection,
  joinVoiceRoom,
  joinVoiceRooms,
  registerVoiceAction,
} from '../../../../v2/socket/namespaces/voice-socket';
import { VOICE_CALL } from '../../constants/voice-call-constants';
export const useLiveCallsListComponentHook = (locationId, isDataRefresh, setDataRefresh, isKeywordFlagEnabled) => {
  const [liveCallList, setLiveCallList] = useState([]);
  const defaultPageSize = 10;
  const voiceCallService = new VoiceCallService();
  const [isLoading, setLoading] = useState(false);
  const [isDetailModalOpen, setDetailModalState] = useState(false);
  const [callData, setCallData] = useState({});
  const [callStatus, setCallStatus] = useState('');
  const logger = new ConsoleLogger('thriveChime', LogLevel.ERROR);
  const deviceController = new DefaultDeviceController(logger);
  const [attendeeId, setAttendeeId] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [joinedRooms, setJoinedRooms] = useState([]);
  const [isInternalHandled, setIsInternalHandled] = useState(false);
  const [stateFilters, setStateFilters] = useState({
    learnerFirstName: '',
    learnerDocId: '',
    relationshipType: '',
    contactName: '',
    voiceCallInsights: [],
  });
  const [allowedFilters, setAllowedFilters] = useState([
    'learnerFirstName',
    'learnerDocId',
    'relationshipType',
    'contactName',
    'voiceCallInsights',
  ]);
  const tableRef = React.useRef();
  const [muteMic, setMuteMic] = useState(true);
  const meetingManager = useMeetingManager();
  const [liveVoiceCallInsights, setLiveVoiceCallInsights] = useState([]);

  useEffect(() => {
    establishVoiceSocketConnection();
  }, []);

  useEffect(() => {
    if (isDataRefresh) {
      refreshTable();
      setDataRefresh(false);
    }
  }, [isDataRefresh]);

  useEffect(() => {
    if (locationId) {
      refreshTable();
      joinVoiceRoom(`voice-${locationId}`);
    }
  }, [locationId]);

  useEffect(() => {
    if (liveCallList && liveCallList.length > 0) {
      const meetingIds = liveCallList.map((call) => call.meetingId);
      // Find rooms that are not yet joined
      const roomsToJoin = meetingIds.filter((meetingId) => !joinedRooms.includes(meetingId));
      if (roomsToJoin.length > 0) {
        joinVoiceRooms(roomsToJoin);
        setJoinedRooms((prevRooms) => [...prevRooms, ...roomsToJoin]);
      }
    }
  }, [liveCallList]);

  useEffect(() => {
    registerVoiceAction({
      event: 'voice_keyword_detected',
      action: (data) => handleSocketEvent(data, VOICE_CALL.SOCKET_EVENTS.VOICE_KEYWORD_DETECTED),
    });
    registerVoiceAction({
      event: 'voice_insights_event',
      action: (data) => handleSocketEvent(data, VOICE_CALL.SOCKET_EVENTS.VOICE_INSIGHTS_EVENT),
    });
    // Cleanup function to disconnect socket and perform any other cleanup on component unmount
    return () => {
      console.log('Cleaning up, disconnecting socket...');
      setJoinedRooms([]);
      disconnectVoiceSocket();
    };
  }, []);

  function handleSocketEvent(data, eventType) {
    console.log('Socket event received: ', data, eventType);

    if (data?.event_type === VOICE_CALL.SOCKET_EVENTS.VOICE_MEETING_STATE_CHANGED) {
      setDataRefresh(true);
      return;
    }

    setIsInternalHandled(true);

    const meetingId = data?.meeting_id;
    tableRef.current.onQueryChange({
      ...tableRef.current.state.query,
      isInternal: true,
      eventType: eventType,
      eventData: data?.event_data || {},
      meetingId: meetingId,
    });
  }

  const onListenClick = async (contact) => {
    try {
      const { meetingId } = contact;
      setLoading(true);
      setCallData(contact);
      setDetailModalState(true);
      const response = await voiceCallService.adminJoinCall(meetingId);
      if (response.status === 'FAILURE') {
        setCallStatus('Call Ended');
      } else {
        const { meetingResponse, attendeeResponse, transactionId } = response;
        setAttendeeId(attendeeResponse.attendeeId);
        setMeetingId(meetingResponse.meetingId);
        setTransactionId(transactionId);
        const meetingSessionConfiguration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
        const meetingSession = new DefaultMeetingSession(meetingSessionConfiguration, logger, deviceController, {
          video: false,
          audio: true,
        });
        const observer = {
          audioVideoDidStart: () => {
            console.log('Started');
          },
          audioVideoDidStop: (sessionStatus) => {
            // See the "Stopping a session" section for details.
            console.log('Stopped with a session status code: ', sessionStatus.statusCode());
            setDetailModalState(false);
            setDataRefresh(true);
            meetingManager.leave();
          },
          audioVideoDidStartConnecting: (reconnecting) => {
            if (reconnecting) {
              // e.g. the WiFi connection is dropped.
              console.log('Attempting to reconnect');
            }
          },
        };
        await meetingManager.join(meetingSessionConfiguration);
        meetingManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
        await meetingManager.start();
        meetingManager.meetingSession.audioVideo.addObserver(observer);
      }
    } catch (error) {
      console.error(error);
      setDetailModalState(false);
    } finally {
      setLoading(false);
    }
  };

  const handleMuteUnmuteMic = (toggleMuteMic) => {
    setMuteMic(toggleMuteMic);
    if (toggleMuteMic) {
      meetingManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
    } else {
      meetingManager.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    }
  };

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        filters[item.column.field] = value;
      }
    }
    return filters;
  };
  const createRequestPayload = (query) => {
    const selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    const tbody = {
      learnerFirstName: selectedFilters.learnerFirstName,
      learnerDocId: selectedFilters.learnerDocId,
      relationshipType: selectedFilters.relationshipType,
      firstName: selectedFilters.contactName,
      lastName: selectedFilters.contactName,
      voiceCallInsights: selectedFilters.voiceCallInsights.join(),
    };
    return { tbody };
  };

  const getLiveCallsListData = async (query) => {
    if (!locationId) return;

    const { orderBy, orderDirection, page, pageSize, isInternal } = query;

    // Handle internal query
    if (isInternalHandled) {
      return handleRealTimeUpdate(query);
    }

    // External data fetch from API
    const { tbody } = createRequestPayload(query);

    setLiveCallList([]); // Clear existing data
    setLoading(true); // Start loading state

    try {
      const data = await voiceCallService.getLiveCallsList({
        locationId,
        status: 'in_Progress',
        pageNumber: page,
        size: pageSize,
        sortBy: orderBy ? orderBy.field : '',
        sortOrder: orderDirection ? orderDirection : '',
        tbody,
      });

      const { content, pageable, totalElements } = data;

      if (content) {
        setLiveCallList(content); // Update live calls with fetched content
        setLoading(false); // End loading state
        setLiveVoiceCallInsights((prevInsights) => {
          // Create a map for fast lookup of existing meetingId insights
          const insightsMap = new Map(prevInsights.map((insight) => [insight.meetingId, insight]));

          // Update or add the insights for the current meeting
          content.forEach((call) => {
            if (call.voiceCallInsights) {
              insightsMap.set(call.meetingId, {
                meetingId: call.meetingId,
                insights: call.voiceCallInsights,
              });
            }
          });

          return Array.from(insightsMap.values());
        });

        return {
          data: content,
          page: pageable.pageNumber,
          totalCount: totalElements,
        };
      } else {
        throw new Error('Data fetch error');
      }
    } catch (error) {
      setLoading(false); // Ensure loading state ends on error
      console.error('Error fetching live calls:', error);
      throw error; // Throw the error to handle it in the calling function if needed
    }
  };

  const handleRealTimeUpdate = (query) => {
    const { page, eventType, eventData, meetingId } = query;

    const updatedCalls = liveCallList.map((call) => {
      if (call.meetingId === meetingId) {
        let updatedEvents = [...(call.voiceCallInsights || [])];
        switch (eventType) {
          case VOICE_CALL.SOCKET_EVENTS.VOICE_KEYWORD_DETECTED:
            if (
              !updatedEvents.find((event) => event.eventDisplayName === VOICE_CALL.VOICE_INSIGHTS.KEYWORDS_DETECTED.label)
            ) {
              updatedEvents = [
                {
                  eventType: eventType,
                  eventDisplayName: VOICE_CALL.VOICE_INSIGHTS.KEYWORDS_DETECTED.label,
                  meetingId,
                  eventData,
                },
                ...updatedEvents,
              ];
            }
            break;
          case VOICE_CALL.SOCKET_EVENTS.VOICE_INSIGHTS_EVENT:
            if (
              eventData?.eventDisplayName &&
              !updatedEvents.find((event) => event.eventDisplayName === eventData.eventDisplayName)
            ) {
              updatedEvents = [
                { eventType: eventType, eventDisplayName: eventData.eventDisplayName, meetingId, eventData },
                ...updatedEvents,
              ];
            }
            break;
          default:
            break;
        }

        return { ...call, voiceCallInsights: updatedEvents };
      }
      return call;
    });

    setLiveCallList(updatedCalls);
    setLiveVoiceCallInsights((prevInsights) => {
      const updatedInsights = prevInsights.filter((insight) => insight.meetingId !== meetingId);
      const newInsight = updatedCalls.find((call) => call.meetingId === meetingId);
      if (newInsight && newInsight.voiceCallInsights) {
        updatedInsights.push({
          meetingId,
          insights: newInsight.voiceCallInsights,
        });
      }

      return updatedInsights;
    });

    setIsInternalHandled(false);

    return {
      data: updatedCalls,
      page,
      totalCount: updatedCalls.length,
    };
  };

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange({ ...tableRef.current.state.query, isInternal: false });
  };

  let tableColumns = [
    {
      title: 'Resident Name',
      field: 'learnerFirstName',
      filtering: true,
      sorting: true,
      defaultSort: 'asc',
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => (
        <Hoverable title={rowData.learnerFirstName}>{rowData.learnerFirstName + ' ' + rowData.learnerLastName}</Hoverable>
      ),
    },
    {
      title: 'DOC ID',
      field: 'learnerDocId',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => <Hoverable title={rowData.learnerDocId}>{rowData.learnerDocId}</Hoverable>,
    },
    {
      title: 'Contact Name',
      field: 'contactName',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { firstName, lastName } = JSON.parse(rowData.contactInfo);
        return <Hoverable title={firstName}>{firstName + ' ' + lastName}</Hoverable>;
      },
    },
    {
      title: 'Relationship',
      field: 'relationshipType',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { relationshipType } = JSON.parse(rowData.contactInfo);
        return <Hoverable title={relationshipType}>{relationshipType}</Hoverable>;
      },
    },
    ...(isKeywordFlagEnabled
      ? [
          {
            title: 'Call Insights',
            field: 'voiceCallInsights',
            filtering: true,
            sorting: false,
            lookup: Object.fromEntries(
              Object.values(VOICE_CALL.VOICE_INSIGHTS)
                .filter(({ value }) => value !== VOICE_CALL.VOICE_INSIGHTS.VOICE_MATCH_RESIDENT.value)
                .map(({ value, label }) => [value, label])
            ),
            render: (rowData) => {
              const { voiceCallInsights } = rowData;
              if (!voiceCallInsights || voiceCallInsights.length === 0) {
                return null; // No insights to display
              }

              // Collect unique events using a Set for event types
              const uniqueInsights = [];
              const seenEventTypes = new Set();

              for (const event of voiceCallInsights) {
                const eventKey = `${event.eventType}-${event.eventDisplayName}`;

                if (!seenEventTypes.has(eventKey)) {
                  uniqueInsights.push(event);
                  seenEventTypes.add(eventKey);
                }
              }

              return (
                <>
                  {uniqueInsights
                    .filter((event) => {
                      const eventType = event.eventDisplayName || event.eventData?.eventDisplayName;
                      return eventType !== VOICE_CALL.VOICE_INSIGHTS.VOICE_MATCH_RESIDENT.label;
                    })
                    .map((event, index) => {
                      const eventDisplayName = event.eventDisplayName || event.eventData?.eventDisplayName;

                      return (
                        <Box key={index}>
                          <VoiceInsightChip key={index} label={eventDisplayName} />
                        </Box>
                      );
                    })}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: 'Actions',
      sorting: false,
      render: (rowData) => {
        const isPrivileged = JSON.parse(rowData.contactInfo).privileged || false;
        return (
          <>
            {isPrivileged ? (
              <Button disabled id="privileged_call">
                <CustomIcons icon={icons.HeadphoneIcon} />
                Privileged
              </Button>
            ) : (
              <Button onClick={() => onListenClick(rowData)} id="voice_call">
                <CustomIcons icon={icons.HeadphoneIcon} />
                Listen
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return {
    tableColumns,
    getLiveCallsListData,
    isLoading,
    defaultPageSize,
    isDetailModalOpen,
    callData,
    attendeeId,
    meetingId,
    callStatus,
    setDetailModalState,
    tableRef,
    handleMuteUnmuteMic,
    muteMic,
    transactionId,
    liveCallList,
    setLiveCallList,
    handleRealTimeUpdate,
    liveVoiceCallInsights,
    isInternalHandled,
    setIsInternalHandled,
  };
};
