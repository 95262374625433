import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import { USER_CARD_DATETIME_FORMAT } from "../../../constants";
import styles from "./pdf.styles"

export default function PDF(props) {
    const { users } = props;
    return (
        <Document
            author="APDS"
            title={props.title}
        >
            {users.map(
                (user, index) => (
                    <Page wrap size="A4" key={index} style={styles.root}>
                        <View fixed style={styles.headerMgn}>
                            <Image
                                src="/assets/logo/orijin-logo-blue.png"
                                style={styles.brandLogo}
                                allowDangerousPaths={true}
                                alt="apds-logo.png"
                            />
                        </View>
                        <View >
                            <View style={styles.reportHeader}>
                                <View>
                                    <View style={{ width: '100%' }}>
                                        <Text style={styles.heading}>{`Learner Login Information`}</Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                {user.userDetail && (
                                    <View>
                                        <View>
                                            <Text style={styles.username}>{`${user.userDetail.firstName} ${user.userDetail.lastName}`}</Text>
                                        </View>
                                        {user.userLocations.length > 0 && (
                                            <View>
                                                <Text style={styles.username}>Current Location: {user.userLocations[0].locationTypeId === 15 ? `${user.userLocations[0].parentLocationName}, ${user.userLocations[0].locationName}` : `${user.userLocations[0].locationName}`}</Text>
                                            </View>
                                        )}
                                        <View style={{ margin: '1rem' }}>
                                            <Text style={styles.userInfoHead}>Connected Corrections</Text>
                                            <View style={{ padding: '.5rem' }}>
                                                <Text style={styles.userInfoDetail}>Username: {user.userDetail.userName || "n/a"}</Text>
                                                <Text style={styles.userInfoDetail}>Password: {user.userDetail.password || "n/a"}</Text>
                                            </View>
                                            <Text style={styles.userInfoHead}>The following educational resources require a login and password. After logging into Connected Corrections click on the corresponding tile/button and you will be prompted to enter a username and password.</Text>
                                        </View>
                                    </View>
                                )}
                                {(user.userServices || [])
                                    .filter(service => service.active === "true")
                                    .map((s, i) =>
                                        <View>
                                            {!s.ssoEnabled && (
                                                <View key={i}>
                                                    {(s.serviceKey != (124) && s.serviceKey != (125)) ?
                                                        <View style={{ margin: '1rem' }}>
                                                            <Text style={styles.userInfoHead}>{s.serviceDisplayName}</Text>
                                                            <View style={{ padding: '.5rem' }}>
                                                                <Text style={styles.userInfoDetail}>Username: {s.externalUserName || "n/a"}</Text>
                                                                <Text style={styles.userInfoDetail}>Password: {s.externalUserPassword || "n/a"}</Text>
                                                            </View>
                                                        </View>
                                                        :
                                                        null
                                                    }
                                                </View>
                                            )}
                                        </View>
                                    )}
                            </View>
                        </View>
                        <Text style={styles.pagination} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                    </Page>
                )
            )}
        </Document>
    );
}